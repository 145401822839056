import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import './PageLoader.css';

interface IProps {
  open: boolean;
}

function PageLoader({ open }: IProps) {
  const nodeRef = useRef(null);

  return (
    <CSSTransition nodeRef={nodeRef} in={open} timeout={250} classNames="pageloader" unmountOnExit>
      <div ref={nodeRef} className="pageloader">
        <div className="pageloader__symbol" />
      </div>
    </CSSTransition>
  );
}

export default PageLoader;
