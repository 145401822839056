import { useRef, useState } from 'react';
import { A11y, Mousewheel, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/a11y';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Icon } from '../../../common/Icon/Icon';
import { Member } from '../../../core/models';
import CommunityMarker from '../CommunityMarker/CommunityMarker';
import './CommunityMarkerGroup.css';

interface IProps {
  members: Member[];
  allMembers: Member[];
  setHotMarker: React.Dispatch<React.SetStateAction<string | null>>;
}

function CommunityMarkerGroup({ members, allMembers, setHotMarker }: IProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [init, setInit] = useState(false);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  return (
    <div
      className="marker-group"
      role="presentation"
      ref={(ref) => {
        if (!ref) return;
        google.maps.OverlayView.preventMapHitsFrom(ref);
        google.maps.OverlayView.preventMapHitsAndGesturesFrom(ref);
      }}
    >
      <button
        className="marker-group__arrow marker-group__arrow--prev rotate-90"
        type="button"
        ref={prevRef}
        hidden={isLocked}
      >
        <span className="sr-only">Previous slide</span>
        <Icon.Chevron className="icon" />
      </button>

      <Swiper
        className={`marker-group__slider${isLocked ? ' locked' : ''}${
          members.length === 2 ? ' two' : members.length === 3 ? ' three' : ''
        }`}
        slidesPerView="auto"
        watchOverflow
        modules={[Mousewheel, A11y, Navigation]}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        grabCursor
        mousewheel={{
          forceToAxis: true,
        }}
        spaceBetween={8}
        onSwiper={() => setInit(true)}
        onLock={() => setIsLocked(true)}
        onUnlock={() => setIsLocked(false)}
      >
        {allMembers
          .map((member, i) => ({
            ...member,
            idx: i,
          }))
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .filter((member) => members.map((el: any) => el.id).includes(member._id))
          .map((member) => (
            <SwiperSlide className="marker-group__slide" key={member._id}>
              <CommunityMarker member={member} setHotMarker={setHotMarker} idx={member.idx} />
            </SwiperSlide>
          ))}
      </Swiper>
      <button
        className="marker-group__arrow marker-group__arrow--next rotate-90"
        type="button"
        ref={nextRef}
        hidden={isLocked}
      >
        <span className="sr-only">Next slide</span>
        <Icon.Chevron className="icon flip-y" />
      </button>
    </div>
  );
}

export default CommunityMarkerGroup;
