import { Helmet } from 'react-helmet-async';

import LandingBanner from '../../Components/LandingBanner/LandingBanner';
import LandingContact from '../../Components/LandingContact/LandingContact';
import LandingPartners from '../../Components/LandingPartners/LandingPartners';
import LandingPeople from '../../Components/LandingPeople/LandingPeople';
import LandingPromo from '../../Components/LandingPromo/LandingPromo';
import { ToBool } from '../../core/helpers';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://leadership.thnk.org';

function LandingPage() {
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={
            ToBool(process.env.REACT_APP_SHOW_LANDING_PAGE) ? `${BASE_URL}` : `${BASE_URL}/landing`
          }
        />
      </Helmet>
      <LandingBanner />
      <LandingPromo
        right={
          <h2 className="h2">
            Tailor-made
            <br />
            leadership
            <br />
            programs
          </h2>
        }
        left={
          <p className="h3">
            Organizational change starts with individual leaders. In our intensive peer-to-peer
            development programs, we guide senior leaders through building critical mindsets and
            behaviors to lead change with heart.
          </p>
        }
      />
      <LandingPeople />
      <LandingPromo
        left={
          <h2 className="h2">THNK of a school that gives you a purpose, instead of homework.</h2>
        }
        right={
          <p className="h3">
            We&apos;re a B Corp. It means we&apos;re committed to positive change. In our case,
            that&apos;s supporting leaders to lead us into a better future. We&apos;ve already got a
            lively, global community of THNKers that are working on that every single day.
          </p>
        }
        mobileSwap
      />
      <LandingPartners />
      <LandingContact />
    </>
  );
}

export default LandingPage;
