import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Marker from '../../../common/CustomMarker/CustomMarker';
import { useScreenSizeContext } from '../../../core/context/screenSize.context';
import { Member } from '../../../core/models';
import CommunityMarker from '../CommunityMarker/CommunityMarker';
import CommunityMarkerGroup from '../CommunityMarkerGroup/CommunityMarkerGroup';
import './CommunityMap.css';
import styles from './styles.json';

interface Props {
  members: Member[];
  center: google.maps.LatLng;
  zoom: number;
  hotMarker: string | null;
  setHotMarker: React.Dispatch<React.SetStateAction<string | null>>;
}

function CommunityMap({ members, center, zoom, hotMarker, setHotMarker }: Props) {
  const { isMobile } = useScreenSizeContext();
  const [map, setMap] = useState<google.maps.Map | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cluster, setCluster] = useState<MarkerClusterer>();
  const groupMarker = useRef<Marker | null>(null);
  const mapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mapRef.current || map) return;
    setMap(
      new window.google.maps.Map(mapRef.current, {
        center,
        zoom,
        maxZoom: 19,
        minZoom: 2,
        restriction: {
          latLngBounds: {
            east: 184,
            north: 85,
            south: -60,
            west: -175,
          },
        },
        styles,
        disableDefaultUI: true,
        zoomControl: true,
        gestureHandling: isMobile ? 'cooperative' : 'greedy',
      }),
    );
  }, [mapRef, center, zoom, map, isMobile]);

  const markers = useMemo(
    () =>
      !map || !members
        ? []
        : members
            .map((member, i) =>
              !member.current_location?.coords ||
              (member.current_location?.coords.lat === 0 &&
                member.current_location?.coords.lng === 0)
                ? null
                : new Marker({
                    map,
                    position: new google.maps.LatLng(member.current_location?.coords),
                    content: (
                      <CommunityMarker member={member} setHotMarker={setHotMarker} idx={i} />
                    ),
                    id: member._id,
                  }),
            )
            .filter((el) => el !== null),
    [map, members, setHotMarker],
  );

  const closeGroups = useCallback(() => {
    groupMarker.current?.overlay.setMap(null);
    groupMarker.current = null;
  }, []);

  useEffect(() => {
    cluster?.clearMarkers();
    closeGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers]);

  useEffect(() => {
    if (!map) return;
    const zoomListener = map.addListener('zoom_changed', closeGroups);

    return () => {
      google.maps.event.removeListener(zoomListener);
    };
  }, [map, closeGroups]);

  useEffect(() => {
    markers.forEach((marker) => {
      if (!marker?.overlay.update) return;
      return marker.overlay.update(hotMarker === marker.id);
    });
  }, [hotMarker, markers]);

  const toggleGroup = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (hiddenMembers: any, position: google.maps.LatLng) => {
      if (groupMarker.current?.position.lat() === position.lat()) {
        closeGroups();
        return;
      }
      closeGroups();
      if (!map) return;
      const group = new Marker({
        map,
        position,
        content: (
          <CommunityMarkerGroup
            members={hiddenMembers}
            allMembers={members}
            setHotMarker={setHotMarker}
          />
        ),
        className: 'group-container',
      });
      group.init();
      setTimeout(() => {
        group.overlay.setMap(map);
      }, 0);
      groupMarker.current = group;
    },
    [map, members, closeGroups, setHotMarker],
  );

  useEffect(() => {
    setCluster(
      new MarkerClusterer({
        map,
        markers: markers.map((marker) => marker?.init()),
        algorithm: new SuperClusterAlgorithm({ maxZoom: 19, radius: 60 }),
        renderer: {
          render: ({ count, position, markers: hiddenMarkers }) => {
            if (!map) return;
            return new Marker({
              map,
              position,
              content: (
                <button
                  className="map-cluster"
                  type="button"
                  onClick={() => {
                    toggleGroup(hiddenMarkers, position);
                  }}
                  ref={(ref) => {
                    if (!ref) return;
                    google.maps.OverlayView.preventMapHitsFrom(ref);
                    google.maps.OverlayView.preventMapHitsAndGesturesFrom(ref);
                  }}
                >
                  {count}
                </button>
              ),
            }).init();
          },
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, markers]);

  return <div className="community-map" ref={mapRef} id="map" />;
}

export default CommunityMap;
