import ReactMarkdown from 'react-markdown';

import { IImage } from '../../core/models';

interface IProps {
  img?: IImage;
  className?: string;
  size?: 'small' | 'medium' | 'large';
}

function Image({ img, className, size }: IProps) {
  if (!img) return null;

  return (
    <>
      <img
        className={`${className || ''}`}
        src={`${process.env.REACT_APP_API_URL}/uploads/${
          img.mimetype?.includes('svg')
            ? img?.uri
            : size && img?.sizes && img.sizes[size]?.uri
            ? img.sizes[size]?.uri
            : img?.uri
        }`}
        alt={img?.alt}
        loading="lazy"
      />
      {!img.copyright ? null : (
        <ReactMarkdown className="image-copyright body-small">{img.copyright}</ReactMarkdown>
      )}
    </>
  );
}

export default Image;
