import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import placeholder from '../../../assets/placeholder.png';
import Image from '../../../common/Image/Image';
import { Member, Tag } from '../../../core/models';
import './CommunityItem.css';

interface Props {
  member: Member;
  hotMarker: string | null;
  setHotMarker: React.Dispatch<React.SetStateAction<string | null>>;
}

function CommunityItem({ member, hotMarker, setHotMarker }: Props) {
  const setHot = useCallback(() => {
    setHotMarker(member._id);
  }, [member._id, setHotMarker]);

  const clearHot = useCallback(() => {
    setHotMarker(null);
  }, [setHotMarker]);

  return (
    <li className="community-item">
      <Link
        className={`community-item__container flex gap-sm${
          hotMarker === member._id ? ' active' : ''
        }`}
        to={`/community/people/${member.slug}`}
        target="_blank"
        rel="noopener noreferrer"
        onMouseEnter={setHot}
        onMouseLeave={clearHot}
        onFocus={setHot}
        onBlur={clearHot}
      >
        <div className="community-item__media" id={`community-member-${member._id}`}>
          {!member.profile_pic ? (
            <img className="cover-img" src={placeholder} alt="THNK member" />
          ) : (
            <Image className="cover-img" img={member.profile_pic} size="small" />
          )}
        </div>
        {!member.firstname && !member.expertises?.length ? null : (
          <div>
            <h2 className="community-item__name h4">
              {member.firstname} {member.lastname}
            </h2>
            {!member.expertises?.length ? null : (
              <div className="community-item__exp truncate l-2">
                {member.expertises.map((el: Tag) => el.name).join(', ')}
              </div>
            )}
          </div>
        )}
      </Link>
    </li>
  );
}

export default CommunityItem;
