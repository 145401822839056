/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable react/jsx-props-no-spreading */
import { useMemo } from 'react';

import { Icon } from '../Icon/Icon';

interface Option {
  value: string;
  label: string;
}

interface Props {
  id: string;
  name: string;
  info?: string;
  label?: string;
  value?: string;
  options: Option[];
  placeholder: string;
  emptyMessage?: string;
  disabled?: boolean;
  disablePlaceholder?: boolean;
  className?: string;
  required?: boolean;
  onChange?(e: React.FormEvent<HTMLSelectElement>): void;
  input?: any;
}

function CustomSelect({
  id,
  name,
  info,
  label,
  value,
  options,
  placeholder,
  emptyMessage,
  disablePlaceholder,
  className = '',
  disabled = false,
  required,
  onChange,
  input,
}: Props) {
  const error = useMemo(
    () =>
      !input
        ? null
        : !input.showError
        ? ''
        : input.isEmpty
        ? 'empty'
        : !input.isValid
        ? 'invalid'
        : '',
    [input],
  );

  return (
    <div className={`input${!error ? '' : ' error'} ${className}`}>
      {!label ? null : !label ? null : (
        <label className="input__label body-label" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="input__wrapper">
        <select
          className="input__field input__field--select"
          name={name}
          id={id}
          required={required}
          value={value || input?.value}
          onChange={onChange || input?.onChange}
          ref={input?.ref}
          disabled={disabled}
          defaultValue={value || input?.value !== undefined ? undefined : ''}
        >
          <option value="" disabled={disablePlaceholder} hidden={disablePlaceholder}>
            {placeholder}
          </option>
          {options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <Icon.Chevron className="input__icon icon" />
        {!info ? null : <div className="input__info caption">{info}</div>}
      </div>
      {!error ? null : (
        <div className="input__error body-small">
          <span>{error === 'empty' ? emptyMessage : ''}</span>
        </div>
      )}
    </div>
  );
}

export default CustomSelect;
