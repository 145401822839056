import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { A11y, Mousewheel } from 'swiper';
import 'swiper/css';
import 'swiper/css/a11y';
import { Swiper, SwiperSlide } from 'swiper/react';

import CustomLink from '../../../common/CustomLink/CustomLink';
import Image from '../../../common/Image/Image';
import { useScreenSizeContext } from '../../../core/context/screenSize.context';
import { slugify } from '../../../core/helpers';
import { IArticle, ILink } from '../../../core/models';
import './ArticleShowcase.css';

interface IProps {
  title?: string;
  desc?: string;
  cta?: ILink;
  articles?: IArticle[];
  className?: string;
  flip?: boolean;
}

function ArticleShowcase({ title, desc, cta, articles, className, flip }: IProps) {
  const { isMobile } = useScreenSizeContext();

  const mainArticle = useMemo(() => {
    if (!articles?.length) return null;
    const article = articles[0];
    if (!article) return null;
    return (
      <Link
        className={`article-showcase__main py-sm px-sm${
          articles?.length <= 3 ? ' article-showcase__main--height' : ''
        }`}
        to={`../blog/${article.slug}`}
        id={`${title ? slugify(title) : 'article'}-1`}
      >
        <div className="article-showcase__item-content">
          <h3 className="article-showcase__item-title truncate l-2 h3">{article.title}</h3>
          {!article.authors.length ? null : (
            <div className="article-showcase__item-authors truncate l-2 body-large">
              {article.authors
                .filter((author) => author.name)
                .map((author) => author.name)
                .join(', ')}
            </div>
          )}
          <div className="article-showcase__item-footer truncate l-2">
            {' '}
            {new Date(article.created).toLocaleString('en-gb', {
              day: '2-digit',
              month: 'long',
              year: 'numeric',
            })}
            {!article.tags.length ? null : (
              <> &bull; {article.tags.map((tag) => tag.name).join(', ')}</>
            )}
          </div>
        </div>
        <Image className="cover-img" img={article.img} size="medium" />
      </Link>
    );
  }, [articles, title]);

  const otherAtricles = useMemo(() => {
    if (!articles?.length) return null;
    return articles.slice(1, 4).map((article, i) =>
      !article ? null : (
        <SwiperSlide className="article-showcase__slide" key={article._id}>
          <Link
            className="article-showcase__item gap-sm"
            to={`../blog/${article.slug}`}
            id={`${title ? slugify(title) : 'article'}-${i + 2}`}
          >
            <div className="article-showcase__item-img">
              <Image className="cover-img" img={article.img} size="small" />
            </div>
            <div className="article-showcase__item-content">
              <h3 className="article-showcase__item-title truncate l-2 h4">{article.title}</h3>
              {!article.authors.length ? null : (
                <div className="article-showcase__item-authors truncate l-2">
                  {article.authors
                    .filter((author) => author.name)
                    .map((author) => author.name)
                    .join(', ')}
                </div>
              )}
              <div className="article-showcase__item-footer truncate l-2 body-small">
                {' '}
                {new Date(article.created).toLocaleString('en-gb', {
                  day: '2-digit',
                  month: 'long',
                  year: 'numeric',
                })}
                {!article.tags.length ? null : (
                  <> &bull; {article.tags.map((tag) => tag.name).join(', ')}</>
                )}
              </div>
            </div>
          </Link>
        </SwiperSlide>
      ),
    );
  }, [articles, title]);

  if (!articles?.length)
    return (
      <div className={`article-showcase${className ? ` ${className}` : ''}${flip ? ' flip' : ''}`}>
        <div className="wrap flex gap-lg">
          <div className="flex flex--center gap-md">
            {!title ? null : <h2 className="article-showcase__title h2">{title}</h2>}
            {!desc ? null : <p className="article-showcase__desc body-large">{desc}</p>}
          </div>
          <div className="article-showcase__container gap-lg">
            {isMobile ? (
              <Swiper
                className="article-showcase__slider"
                slidesPerView="auto"
                watchOverflow
                modules={[Mousewheel, A11y]}
                mousewheel={{
                  forceToAxis: true,
                }}
                spaceBetween={24}
              >
                <SwiperSlide className="article-showcase__slide block-loader" />
                <SwiperSlide className="article-showcase__slide block-loader" />
                <SwiperSlide className="article-showcase__slide block-loader" />
              </Swiper>
            ) : (
              <div className="article-showcase__item-container flex gap-md">
                <SwiperSlide className="article-showcase__slide block-loader" />
                <SwiperSlide className="article-showcase__slide block-loader" />
                <SwiperSlide className="article-showcase__slide block-loader" />
              </div>
            )}
            <div className="article-showcase__main block-loader" />
          </div>
        </div>
      </div>
    );

  return (
    <section
      className={`article-showcase${className ? ` ${className}` : ''}${flip ? ' flip' : ''}`}
    >
      <div className="wrap flex gap-lg">
        {!title && !desc ? null : (
          <div className="flex flex--center gap-md">
            {!title ? null : <h2 className="article-showcase__title h2">{title}</h2>}
            {!desc ? null : <p className="article-showcase__desc body-large">{desc}</p>}
          </div>
        )}
        <div className="article-showcase__container gap-lg">
          {!articles?.length ? null : (
            <>
              {!otherAtricles?.length ? null : isMobile ? (
                <Swiper
                  className="article-showcase__slider"
                  slidesPerView="auto"
                  watchOverflow
                  modules={[Mousewheel, A11y]}
                  mousewheel={{
                    forceToAxis: true,
                  }}
                  spaceBetween={24}
                >
                  {otherAtricles}
                </Swiper>
              ) : (
                <div className="article-showcase__item-container flex gap-md">{otherAtricles}</div>
              )}
              {!mainArticle ? null : mainArticle}
            </>
          )}
        </div>
        {!cta ? null : (
          <CustomLink className="article-showcase__cta btn btn--secondary" link={cta} />
        )}
      </div>
    </section>
  );
}

export default ArticleShowcase;
