import { useRef } from 'react';

import { IImage } from '../../core/models';
import CustomDropdown from '../Dropdown/CustomDropdown';
import { Icon } from '../Icon/Icon';
import Image from '../Image/Image';
import './CustomImageField.css';

interface IProps {
  image: File | null | '';
  setImage: React.Dispatch<React.SetStateAction<File | null | ''>>;
  thumbnail: IImage | undefined;
}

function CustomImageField({ image, setImage, thumbnail }: IProps) {
  const imgInput = useRef<HTMLInputElement>(null);
  const onPictureSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files = [] } = e.currentTarget;

    if (files && files.length > 0) {
      const file = files[0];

      if (file) {
        setImage(file);
      }
    }
  };

  return (
    <div className="image-field">
      <CustomDropdown
        selected=""
        items={
          (!thumbnail && !image) || image === ''
            ? [{ value: 'add', label: 'Add photo' }]
            : [
                { value: 'change', label: 'Change photo' },
                { value: 'remove', label: 'Remove photo' },
              ]
        }
        handleChange={(e: React.MouseEvent<HTMLLIElement, MouseEvent>, val: string) => {
          switch (val) {
            case 'add':
              if (!imgInput.current) return;
              imgInput.current.click();
              break;
            case 'change':
              if (!imgInput.current) return;
              imgInput.current.click();
              break;
            case 'remove':
              if (!imgInput.current) return;
              imgInput.current.value = '';
              setImage('');
              break;
            default:
              break;
          }
        }}
      >
        <button className="image-field__btn" type="button">
          {(!thumbnail && !image) || image === '' ? (
            <Icon.Camera className="icon-large" />
          ) : (
            <Image className="image-field__img cover-img" img={thumbnail} size="small" />
          )}
        </button>
      </CustomDropdown>
      <input
        id="profile-picture-input"
        type="file"
        accept="image/*"
        hidden
        ref={imgInput}
        onChange={onPictureSelect}
      />
    </div>
  );
}

export default CustomImageField;
