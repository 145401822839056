import UserMenu from '../../../Components/User/UserMenu/UserMenu';

function Overview() {
  return (
    <div className="user-overview">
      <div className="wrap wrap--xs">
        <UserMenu />
      </div>
    </div>
  );
}

export default Overview;
