import { useEffect, useRef, useState } from 'react';

import './HubSpotForm.css';

interface IProps {
  portalId: string;
  formId: string;
  region: string;
  id: string;
}

function HubSpotForm({ portalId, formId, region, id }: IProps) {
  const [counter, setCounter] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const refTimer = useRef<any>(null);

  useEffect(() => {
    const script = document.querySelector('[src="//js.hsforms.net/forms/embed/v2.js"]');
    if (script) return;
    const newScript = document.createElement('script');
    newScript.src = '//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(newScript);
  }, []);

  useEffect(() => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        region,
        portalId,
        formId,
        target: `#${id}`,
      });
    } else {
      refTimer.current = setTimeout(() => {
        setCounter((prevState) => {
          const newState = prevState + 1;
          return newState;
        });
      }, 200);
    }
    return () => {
      if (refTimer.current) clearTimeout(refTimer.current);
    };
  }, [counter, formId, id, portalId, region]);

  return <div id={id} />;
}

export default HubSpotForm;
