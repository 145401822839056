import React from 'react';

import { CustomInputInteface } from '../../core/models/inputs';
import CustomInput from '../CustomInput/CustomInput';
import './LocationInput.css';

interface ILocation {
  city: string;
  country: string;
  coords: {
    lat: number;
    lng: number;
  };
}

interface ILocationInput {
  id: string;
  name: string;
  placeholder: string;
  input: CustomInputInteface;
  label: string;
  onChange?: (location: ILocation) => void;
  autoComplete?: string;
}

function LocationInput({
  id,
  name,
  placeholder,
  input,
  label,
  onChange = () => {},
  autoComplete = 'on',
}: ILocationInput) {
  React.useEffect(() => {
    const element = document.getElementById(id);

    const { maps } = google;

    if (element) {
      // eslint-disable-next-line no-new
      const searchBox = new maps.places.SearchBox(element as HTMLInputElement);

      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces();

        if (places && places?.length > 0) {
          const place = places[0];

          input.setValue(place.formatted_address || '');

          const city =
            place.address_components?.find((component) => component.types.includes('locality'))
              ?.long_name || '';

          const country =
            place.address_components?.find((component) => component.types.includes('country'))
              ?.long_name || '';

          const lat = place.geometry?.location?.lat() || 0.0;

          const lng = place.geometry?.location?.lng() || 0.0;

          onChange({
            city,
            country,
            coords: {
              lat,
              lng,
            },
          });
        }
      });
    }
  }, [id, input, onChange]);

  return (
    <div className="location-input">
      <CustomInput
        label={label}
        type="text"
        name={name}
        id={id}
        input={input}
        placeholder={placeholder}
        autoComplete={autoComplete}
      />
    </div>
  );
}

export default LocationInput;
