import React from 'react';
import ReactMarkdown from 'react-markdown';

import CustomLink from '../../common/CustomLink/CustomLink';
import { ILink } from '../../core/models';
import './Introduction.css';

interface IProps {
  title: string;
  desc?: string;
  cta?: ILink;
  background?: 'red' | 'white' | 'dark-blue' | 'lite-blue';
}

function Introduction({ title, desc, cta, background }: IProps) {
  return (
    <section
      className={`introduction section-bg${
        !background || background === 'red'
          ? ' section-bg--secondary'
          : background === 'white'
          ? ' section-bg--white'
          : background === 'dark-blue'
          ? ' section-bg--dark'
          : background === 'lite-blue'
          ? ' section-bg--primary'
          : ''
      }`}
    >
      <div className="wrap">
        <div className="introduction__container gap-lg">
          <div className="introduction__left">
            <h2 className="introduction__title h2">
              <ReactMarkdown
                components={{
                  p: React.Fragment,
                }}
              >
                {title}
              </ReactMarkdown>
            </h2>
          </div>
          <div className="introduction__right gap-md">
            {!desc ? null : <ReactMarkdown className="introduction__desc h3">{desc}</ReactMarkdown>}
            {!cta ? null : <CustomLink className="introduction__cta" link={cta} />}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Introduction;
