/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import { useNavigate, useParams } from 'react-router-dom';

import placeholder from '../../assets/placeholder.png';
import Image from '../../common/Image/Image';
import PageLoader from '../../common/PageLoader/PageLoader';
import PageTitle from '../../common/PageTitle/PageTitle';
import { useDataContext } from '../../core/context/data.context';
import useFetch from '../../core/hooks/useFetch';
import { Member } from '../../core/models';
import PageNotFound from '../PageNotFound/PageNotFound';
import './People.css';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;
const APP_BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

interface IResult {
  result: any;
  doFetch: any;
  loading: boolean;
  error: any;
}

function People() {
  const {
    data: { generalData },
  } = useDataContext();
  const modalContainer: HTMLElement | null = document.getElementById('modal');
  const navigate = useNavigate();
  const { slug } = useParams();
  const { result, doFetch, error }: IResult = useFetch(
    `${BASE_URL}/users/members?source=platform&slug=${slug}`,
  );

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  const onClick = useCallback(
    (param: string, value: string) => {
      navigate(`/community?${param}=${value}`);
    },
    [navigate],
  );

  const member: Member = result?.data.items[0];

  if (error || (result && !member)) return <PageNotFound />;

  return (
    <>
      <Helmet>
        {!member?.seo?.title && !generalData?.seo?.title && !member?.name ? null : (
          <title>{member?.seo?.title || member?.name || generalData?.seo?.title}</title>
        )}
        <link rel="canonical" href={`${APP_BASE_URL}/community/people/${slug}`} />
        {!member?.seo?.description && !generalData?.seo?.description ? null : (
          <meta
            name="description"
            content={member?.seo?.description || generalData?.seo?.description}
          />
        )}
      </Helmet>
      {!modalContainer ? null : createPortal(<PageLoader open={!member} />, modalContainer)}
      {!member ? null : (
        <section className="people">
          <div className="wrap">
            <PageTitle
              title={`${member.firstname} ${member.lastname}`}
              backLabel={window.history.length > 1 ? 'Back' : 'Community'}
              backPath={window.history.length > 1 ? -1 : '/community'}
            />
            <div className="people__container gap-lg">
              <div className="people__details flex gap-md py-sm px-sm">
                <div className="people__img-container">
                  {!member.profile_pic ? (
                    <img className="people__img cover-img" src={placeholder} alt="THNK member" />
                  ) : (
                    <Image
                      className="people__img cover-img"
                      img={member.profile_pic}
                      size="medium"
                    />
                  )}
                </div>
                {member.company && member.title && (
                  <div className="people__item">
                    <div>
                      <span className="h3">
                        {member.title} at {member.company.name}
                      </span>
                    </div>
                  </div>
                )}
                <div className="people__info flex gap-sm">
                  {member.pronouns && (
                    <div className="people__item gap-xs">
                      <span className="people__item-label h4">Pronouns</span>
                      <span className="body-large">{member.pronouns}</span>
                    </div>
                  )}
                  {member.current_location && (
                    <div className="people__item gap-xs">
                      <span className="people__item-label h4">Based in</span>
                      <span className="body-large">
                        {' '}
                        {member.current_location?.city}
                        {member.current_location?.country
                          ? `, ${member.current_location?.country}`
                          : null}
                      </span>
                    </div>
                  )}
                  {member.expertises && member.expertises.length > 0 && (
                    <div className="people__item gap-xs">
                      <span className="people__item-label h4">Experience & interests</span>
                      <span className="body-large">
                        <ul className="people__list">
                          {member.expertises.map((el) => (
                            <li key={el.name}>
                              <button
                                className="people__link"
                                type="button"
                                onClick={() => onClick('expertises', el.slug || '')}
                                style={{ fontWeight: '500' }}
                              >
                                {el.name}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </span>
                    </div>
                  )}
                  {member.member_classes && member.member_classes.length > 0 && (
                    <div className="people__item gap-xs">
                      <span className="people__item-label h4">THNK Classes</span>
                      <span className="body-large">
                        <ul className="people__list">
                          {member.member_classes.map((el) => (
                            <li key={el.name}>
                              <button
                                className="people__link"
                                type="button"
                                onClick={() => onClick('classes', el.slug || '')}
                                style={{ fontWeight: '500' }}
                              >
                                {el.name}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </span>
                    </div>
                  )}
                  {member.community_roles && member.community_roles.length > 0 && (
                    <div className="people__item gap-xs">
                      <span className="people__item-label h4">THNK Role</span>
                      <span className="body-large">
                        <ul className="people__list">
                          {member.community_roles.map((el) => (
                            <li key={el.name}>
                              <button
                                className="people__link"
                                type="button"
                                onClick={() => onClick('roles', el.slug || '')}
                                style={{ fontWeight: '600' }}
                              >
                                {el.name}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </span>
                    </div>
                  )}
                </div>
                {member.social &&
                  Object.keys(member.social).map((el) =>
                    !member.social || !member.social[el as keyof Member['social']] ? null : (
                      <a
                        className="people__item-social btn btn--dark"
                        href={member.social ? member.social[el as keyof Member['social']] : '/'}
                        target="_blank"
                        rel="noreferrer"
                        key={el}
                      >
                        Connect with me on{' '}
                        {el === 'linkedin' ? 'LinkedIn' : el[0].toUpperCase() + el.slice(1)}
                      </a>
                    ),
                  )}
              </div>
              <div className="people__content flex gap-lg">
                {!member.bio ? null : (
                  <ReactMarkdown className="people__bio markdown">{member.bio}</ReactMarkdown>
                )}
                {!member.life_questions?.length ? null : (
                  <div className="people__questions flex gap-md px-sm py-sm">
                    {member.life_questions.map((item: any) => (
                      <div className="flex gap-xs" key={item.question._id}>
                        <h2 className="h4">{item.question.question}</h2>
                        <p>{item.answer}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default People;
