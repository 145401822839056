import React from 'react';

import CustomButton from '../CustomButton/CustomButton';
import './ToggleSelect.css';

interface IToggleItem {
  name: string;
  option: string;
  icon?: React.ReactNode;
}

interface IToggleSelectProps {
  items: IToggleItem[];
  selected: number;
  label?: string;
  onChange?: (item: string) => void;
}

function ToggleSelect({ items, label, selected, onChange = () => {} }: IToggleSelectProps) {
  const [selectedIndex, setSelectedIndex] = React.useState<number>(selected);

  React.useEffect(() => {
    setSelectedIndex(selected);
  }, [selected]);

  const handleClick = React.useCallback(
    (i: number) => {
      setSelectedIndex(i);
      onChange(items[i].option);
    },
    [items, onChange],
  );

  return (
    <div className="toggle-select">
      {!label ? null : (
        /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
        <label className="input__label">{label}</label>
      )}
      <div className="toggle-select__container">
        {items.map((item, i) => (
          <CustomButton
            key={item.name}
            className={`toggle-select__item${
              i === items.length - 1 || i === selectedIndex - 1 || i === selectedIndex
                ? ''
                : ' divider'
            }${i === selectedIndex ? ' selected' : ''}`}
            type="button"
            onClick={() => handleClick(i)}
          >
            {item.icon}
            {item.name}
          </CustomButton>
        ))}
      </div>
    </div>
  );
}

export default ToggleSelect;
