import ReactMarkdown from 'react-markdown';

import './Title.css';

interface IProps {
  label?: string;
  title: string;
}

function Title({ label, title }: IProps) {
  return (
    <section className="title section">
      <div className="wrap">
        <div className="flex gap-md">
          {!label ? null : <div className="title__label h3">{label}</div>}
          <h2 className="title__title h2">
            <ReactMarkdown
              components={{
                em: 'span',
              }}
            >
              {title}
            </ReactMarkdown>
          </h2>
        </div>
      </div>
    </section>
  );
}

export default Title;
