import { useCallback, useEffect, useRef } from 'react';

import './ScrollSpy.css';

interface IProps {
  items: {
    target: HTMLDivElement | null;
    label: string;
  }[];
}

function ScrollSpy({ items }: IProps) {
  const buttons = useRef<HTMLButtonElement[] | null[]>([]);
  const containerRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (!items.length || !buttons.current?.length) return;

    const watchTargets = () => {
      const yPositions = items.map((item) => {
        if (!item.target) {
          return null;
        }
        return item.target.offsetTop - 150;
      });
      yPositions.forEach((position, i) => {
        if (!position) return;
        if (window.scrollY >= position) {
          buttons.current.forEach((item) => item?.classList.remove('active'));
          buttons.current[i]?.classList.add('active');
          if (!containerRef.current) return;
          containerRef.current.scroll({
            left: (buttons.current[i]?.offsetLeft || 0) - 10,
          });
        }
      });
    };

    document.addEventListener('scroll', watchTargets, { passive: true });

    return () => {
      document.removeEventListener('scroll', watchTargets);
    };
  }, [items]);

  const scrollToTarget = useCallback((target: HTMLDivElement | null) => {
    if (!target) return;
    window.scroll({
      top: target.offsetTop - 140,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="scrollspy">
      <ul className="scrollspy__container gap-sm" ref={containerRef}>
        {items.map((item, i) => (
          <li className="scrollspy__item" key={item.label}>
            <button
              className={`scrollspy__btn${i === 0 ? ' active' : ''}`}
              type="button"
              ref={(ref) => {
                buttons.current[i] = ref;
              }}
              onClick={() => scrollToTarget(item.target)}
              aria-label={`Scroll to ${item.label}`}
            >
              {item.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ScrollSpy;
