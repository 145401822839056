import { useEffect, useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import Image from '../../../common/Image/Image';
import Message from '../../../common/Message/Message';
import Pager from '../../../common/Pager/Pager';
import useFetch from '../../../core/hooks/useFetch';
import { IArticle } from '../../../core/models';
import './ArticleLister.css';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;
const SEARCHABLE_FIELDS = ['title', 'body', 'authors', 'summary'];

interface IProps {
  category: string;
  searchValue: string;
}

function ArticleLister({ category, searchValue }: IProps) {
  const [searchParams] = useSearchParams();
  const page = useMemo(() => searchParams.get('page') || '1', [searchParams]);
  const { result, doFetch, loading, error } = useFetch(
    `${BASE_URL}/articles/published?populate=tags&populate=img&populate=authors${
      !category ? '' : `&tags=${category}`
    }${
      !searchValue
        ? ''
        : SEARCHABLE_FIELDS.reduce((str, value) => `${str}&${value}=${searchValue}`, '')
    }&per_page=16&page=${page}&sort=created:DESC`,
  );

  useEffect(() => {
    doFetch();
  }, [category, searchValue, page, doFetch]);

  const articlesList = useMemo(
    () =>
      result?.data.items.map((item: IArticle) => (
        <li className="articles-lister__item" key={item._id}>
          <Link className="articles-lister__link flex" to={`/blog/${item.slug}`}>
            <div className="articles-lister__img">
              {item.img ? <Image className="cover-img" img={item.img} size="small" /> : null}
            </div>
            <div className="articles-lister__content flex">
              <h2 className="articles-lister__title truncate l-2 h4">{item.title}</h2>
              {!item.authors.length ? null : (
                <div className="articles-lister__item-authors truncate l-2">
                  {item.authors
                    .filter((author) => author.name)
                    .map((author) => author.name)
                    .join(', ')}
                </div>
              )}
              <div className="articles-lister__date truncate l-2 body-small">
                {' '}
                {new Date(item.created).toLocaleString('en-gb', {
                  day: '2-digit',
                  month: 'long',
                  year: 'numeric',
                })}
                {!item.tags.length ? null : (
                  <> &bull; {item.tags.map((tag) => tag.name).join(', ')}</>
                )}
              </div>
            </div>
          </Link>
        </li>
      )) || [],
    [result?.data],
  );

  return (
    <section className="articles-lister">
      <div className="wrap">
        {!error ? null : <Message text={error.message} status="error" />}
        {!loading ? null : (
          <div className="page-loader fade-in" style={{ margin: '16px 0' }}>
            <span className="sr-only">Loading</span>
            <div className="loader" />
          </div>
        )}
        {!result && !error ? <div className="articles-lister__placeholder" /> : null}
        {articlesList.length ? (
          <ul className="articles-lister__list gap-md">{articlesList}</ul>
        ) : result ? (
          <h2 className="articles-lister__message h3">No articles found.</h2>
        ) : null}
        {articlesList.length && result?.data?.pager?.total > 1 ? (
          <Pager total={result.data.pager.total} current={+page} />
        ) : null}
      </div>
    </section>
  );
}

export default ArticleLister;
