/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode, useEffect, useRef } from 'react';

function useOutsideAlerter(
  ref: React.RefObject<HTMLElement>,
  onClick: () => void,
  detectTab?: boolean,
) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (!ref) return;
      if (ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    }

    function handleTabOutside(event: any) {
      if (!ref) return;
      if (event.keyCode === 9 || (event.shiftKey && event.keyCode === 9)) {
        if (!ref.current?.contains(event.target)) {
          onClick();
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    if (detectTab) {
      document.addEventListener('keyup', handleTabOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (detectTab) {
        document.removeEventListener('keyup', handleTabOutside);
      }
    };
  }, [detectTab, onClick, ref]);
}

export default function OutsideAlerter({
  children,
  onClick,
  detectTab,
}: {
  children: ReactNode;
  onClick: () => void;
  detectTab?: boolean;
}) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClick, detectTab);

  return <div ref={wrapperRef}>{children}</div>;
}
