import { useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

import { Icon } from '../../../common/Icon/Icon';
import Image from '../../../common/Image/Image';
import LinkRenderer from '../../../common/LinkRenderer/LinkRenderer';
import useIntersection from '../../../core/hooks/useIntersection';
import { IArticle } from '../../../core/models';
import './ArticleFeatured.css';

interface IProps {
  article: IArticle;
  styling: 'blue' | 'grey' | 'white';
}

function ArticleFeatured({ article, styling = 'blue' }: IProps) {
  const ref = useRef(null);
  const { wasIntersecting } = useIntersection(ref, 0.2);

  if (!article) return null;

  return (
    <section className={`article-featured section article-featured--${styling}`} ref={ref}>
      <div className="wrap wrap--lg">
        <div className={`article-featured__container${wasIntersecting ? ' show' : ''}`}>
          <div className="article-featured__content-container py-md px-md">
            <div className="article-featured__content flex gap-md">
              <div className="flex gap-sm">
                <h2 className="article-featured__title h3">{article.title}</h2>
                {!article.authors.length ? null : (
                  <div className="article-featured__authors body-large">
                    {article.authors
                      .map((author) => `${author.firstname} ${author.lastname}`)
                      .join(', ')}
                  </div>
                )}
                <div className="article-featured__info">
                  {' '}
                  {new Date(article.created).toLocaleString('en-gb', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                  })}
                  {!article.tags.length ? null : (
                    <> &bull; {article.tags.map((tag) => tag.name).join(', ')}</>
                  )}
                </div>
              </div>
              <ReactMarkdown
                className="article-featured__summary body-large"
                components={{
                  a: LinkRenderer,
                }}
              >
                {article.summary}
              </ReactMarkdown>
              <Link
                className={`article-featured__cta btn${
                  styling === 'blue' ? ' btn--light' : ' btn--dark'
                }`}
                to={`../blog/${article.slug}`}
              >
                Read more
                <Icon.Chevron className="icon rotate-minus-90" />
              </Link>
            </div>
          </div>
          <div className="article-featured__img">
            <Image className="cover-img" img={article.img} size="medium" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ArticleFeatured;
