import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useScreenSizeContext } from '../../core/context/screenSize.context';
import { range } from '../../core/helpers';
import { Icon } from '../Icon/Icon';
import './Pager.css';

interface Props {
  current: number;
  total: number;
}

function Pager({ current, total }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile } = useScreenSizeContext();

  const handleClick = useCallback(
    (val: number) => {
      searchParams.set('page', `${val}`);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const paginationRange = useMemo(() => {
    const siblingCount = isMobile ? 1 : 2;
    const totalPageNumbers = isMobile ? 6 : 8;

    /* Case 1: If the number of pages is less than the page numbers */
    if (totalPageNumbers >= total) {
      return range(1, total);
    }

    /* Calculate left and right sibling index and make sure they are within range 1 and totalPageCount */
    const leftSiblingIndex = Math.max(current - siblingCount, 1);
    const rightSiblingIndex = Math.min(current + siblingCount, total);

    /* We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount.*/
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < total - (isMobile ? 1 : 2);

    const firstPageIndex = 1;
    const lastPageIndex = total;

    /* Case 2: No left dots to show, but rights dots to be shown */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 2 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, { value: '...', key: 'dots1' }, total];
    }

    /* Case 3: No right dots to show, but left dots to be shown */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 2 + 2 * siblingCount;
      const rightRange = range(total - rightItemCount + 1, total);
      return [firstPageIndex, { value: '...', key: 'dots2' }, ...rightRange];
    }

    /* Case 4: Both left and right dots to be shown */
    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [
        firstPageIndex,
        { value: '...', key: 'dots3' },
        ...middleRange,
        { value: '...', key: 'dots4' },
        lastPageIndex,
      ];
    }
    return [];
  }, [total, current, isMobile]);

  return (
    <div className="pager">
      <div className="pager__container">
        {current === 1 ? null : (
          <button
            type="button"
            className="pager__arrow pager__arrow--prev"
            onClick={() => handleClick(current - 1)}
          >
            <span className="sr-only">Previous page</span>
            <Icon.Chevron className="icon" />
          </button>
        )}
        <ul className="pager__list">
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {paginationRange.map((el: any) => {
            if (el.value === '...')
              return (
                <li className="pager__dots" key={el.key}>
                  {el.value}
                </li>
              );
            return (
              <li className="pager__item" key={el}>
                <button
                  className={`pager__btn${current === el ? ' pager__btn--current' : ''}`}
                  type="button"
                  onClick={() => handleClick(+el)}
                >
                  {el}
                </button>
              </li>
            );
          })}
        </ul>
        {current === total ? null : (
          <button
            type="button"
            className="pager__arrow pager__arrow--next"
            onClick={() => handleClick(current + 1)}
          >
            <span className="sr-only">Previous page</span>
            <Icon.Chevron className="icon" />
          </button>
        )}
      </div>
    </div>
  );
}

export default Pager;
