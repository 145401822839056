import { AlertContextProvider } from './alert.context';
import { DataContextProvider } from './data.context';
import { ScreenSizeContextProvider } from './screenSize.context';
import { ThemeContextProvider } from './theme.context';
import { UserContextProvider } from './user.context';

interface Props {
  children: JSX.Element;
}

const combineComponents = (...components: Array<(arg0: Props) => JSX.Element>) =>
  components.reduce(
    (
      AccumulatedComponents: (arg0: Props) => JSX.Element,
      CurrentComponent: (arg0: Props) => JSX.Element,
    ) =>
      function ({ children }: Props) {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      },
    ({ children }: { children: JSX.Element }) => children,
  );

const providers = [
  ScreenSizeContextProvider,
  ThemeContextProvider,
  AlertContextProvider,
  UserContextProvider,
  DataContextProvider,
];
export default combineComponents(...providers);
