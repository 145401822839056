import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { A11y, EffectFade, Mousewheel, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

import CustomLink from '../../common/CustomLink/CustomLink';
import Image from '../../common/Image/Image';
import LineClamp from '../../common/LineClamp/LineClamp';
import { slugify } from '../../core/helpers';
import { IImage, ILink } from '../../core/models';
import './QuoteSlider.css';

interface IProps {
  title: string;
  quoteSliderItems: {
    quote: string;
    img?: IImage;
    cta: ILink;
  }[];
}

function QuoteSlider({ title, quoteSliderItems }: IProps) {
  const slides = useMemo(
    () =>
      quoteSliderItems?.map((slide) => (
        <SwiperSlide className="quote-slider__slide" key={slide.quote}>
          <div className="quote-slider__img-container">
            <Image className="quote-slider__img cover-img" img={slide.img} size="medium" />
          </div>
          <div className="quote-slider__content flex px-md py-md gap-md">
            <LineClamp className={`quote-slider__quote${slide.cta.text ? ' h3' : ' h2'}`} lines={5}>
              <ReactMarkdown>{slide.quote}</ReactMarkdown>
            </LineClamp>
            {!slide.cta.text ? null : (
              <CustomLink
                className="quote-slider__cta"
                link={slide.cta}
                key={slide.cta.text}
                target="_blank"
              />
            )}
          </div>
        </SwiperSlide>
      )),
    [quoteSliderItems],
  );

  return (
    <section className="quote-slider section">
      <div className="wrap flex gap-md">
        <h2 className="quote-slider__title h3">{title}</h2>
        <Swiper
          className="quote-slider__slider flex gap-md"
          watchOverflow
          effect="fade"
          modules={[Mousewheel, A11y, EffectFade, Pagination]}
          pagination={{
            clickable: true,
            renderBullet: (index, className) =>
              `<span
                class="${className}"
                tabindex="0"
                role="button"
                aria-label="Go to slide ${index}"
                id="${slugify(title)}-${index + 1}"
                aria-current="true"
              ></span>`,
          }}
          grabCursor
          loop
          mousewheel={{
            forceToAxis: true,
          }}
        >
          {slides}
        </Swiper>
      </div>
    </section>
  );
}

export default QuoteSlider;
