import { useCallback } from 'react';

import placeholder from '../../../assets/placeholder.png';
import Image from '../../../common/Image/Image';
import { Member } from '../../../core/models';
import './CommunityMarker.css';

interface Props {
  member: Member;
  setHotMarker: React.Dispatch<React.SetStateAction<string | null>>;
  idx: number;
}

function CommunityMarker({ member, setHotMarker, idx }: Props) {
  const setHot = useCallback(() => {
    setHotMarker(member._id);
  }, [member._id, setHotMarker]);

  const clearHot = useCallback(() => {
    setHotMarker(null);
  }, [setHotMarker]);

  return (
    <a
      className={`member-marker idx-${idx % 6}`}
      href={`/community/people/${member.slug}`}
      target="_blank"
      rel="noopener noreferrer"
      onMouseEnter={setHot}
      onMouseLeave={clearHot}
      onFocus={setHot}
      onBlur={clearHot}
    >
      {!member.profile_pic ? (
        <img className="member-marker__img cover-img" src={placeholder} alt="THNK member" />
      ) : (
        <Image className="member-marker__img cover-img" img={member.profile_pic} size="small" />
      )}
    </a>
  );
}

export default CommunityMarker;
