import { useRef } from 'react';

import IntersectionAnimation from '../../common/IntersectionAnimation/IntersectionAnimation';
import useIntersection from '../../core/hooks/useIntersection';
import './LandingPromo.css';

interface Props {
  left: JSX.Element;
  right: JSX.Element;
  mobileSwap?: boolean;
}

function LandingPromo({ left, right, mobileSwap }: Props) {
  const leftRef = useRef(null);
  const rightRef = useRef(null);
  const { wasIntersecting: leftWasIntersecting } = useIntersection(leftRef, 0.5);
  const { wasIntersecting: rightWasIntersecting } = useIntersection(rightRef, 0.2);

  return (
    <section
      className={`landing-promo${mobileSwap ? ' landing-promo--mobile-swap' : ''}${
        !rightWasIntersecting ? '' : ' landing-promo--active'
      }`}
    >
      <div className="wrap">
        <div className="landing-promo__container">
          <div className="landing-promo__right-container">
            <div className="landing-promo__right" ref={rightRef}>
              {right}
            </div>
          </div>
          <div className="landing-promo__left-container">
            <IntersectionAnimation side="bottom" visible={leftWasIntersecting}>
              <div className="landing-promo__left" ref={leftRef}>
                {left}
              </div>
            </IntersectionAnimation>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LandingPromo;
