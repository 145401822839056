export const BREAKPOINTS = {
  mobile: 768,
  tablet: 1280,
};
export const HEADER_HEIGHT = 85;

export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$&+,:;=?@#|'"<>.^*()%!\-_])[A-Za-z\d$&+,:;=?@#|'"<>.^*()%!\-_]{8,}$/;

export const AUTH_TOKEN = 'authenticationToken';
