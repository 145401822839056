/* eslint-disable react/jsx-props-no-spreading */
import { Outlet, useLocation } from 'react-router-dom';

import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import ScrollToTop from './Components/ScrollToTop';
import './style/animation.css';
import './style/base.css';
import './style/buttons.css';
import './style/forms.css';
import './style/inputs.css';
import './style/layout.css';
import './style/loader.css';
import './style/print.css';
import './style/reset.css';
import './style/typography.css';
import './style/variables.css';

function App() {
  const location = useLocation();

  return (
    <div className="app">
      {location.pathname.includes('user/invited') ? null : (
        <>
          <ScrollToTop />
          <Header />
        </>
      )}
      <main>
        <Outlet />
      </main>
      {location.pathname.includes('user/invited') ? null : <Footer />}
    </div>
  );
}

export default App;
