import { ReactNode, useEffect, useRef } from 'react';

import { useScreenSizeContext } from '../../core/context/screenSize.context';

interface IProps {
  children: ReactNode;
  lines: number;
  className?: string;
}

function LineClamp({ children, lines, className }: IProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { width } = useScreenSizeContext();

  useEffect(() => {
    if (!ref.current) return;
    const style = window.getComputedStyle(ref.current, null);
    const lineHeight = style.getPropertyValue('line-height');
    ref.current.style.maxHeight = `${+lineHeight.replace('px', '') * lines}px`;
    ref.current.style.webkitLineClamp = `${lines}`;
  }, [lines, width]);

  return (
    <div className={`truncate${className ? ` ${className}` : ''}`} ref={ref}>
      {children}
    </div>
  );
}

export default LineClamp;
