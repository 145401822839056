/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactComponent as CorpLogoSmall } from '../../assets/B-Corp-Logo-small.svg';
import { ReactComponent as CorpLogo } from '../../assets/B-Corp-Logo.svg';
import { ReactComponent as Logo } from '../../assets/logo-monochrome.svg';
import { useScreenSizeContext } from '../../core/context/screenSize.context';
import { mailTo } from '../../core/helpers';
import './LandingFooter.css';

function LandingFooter() {
  const { isMobile } = useScreenSizeContext();

  return (
    <footer className="landing-footer">
      <div className="wrap">
        <div className="landing-footer__container">
          <div className="landing-footer__col landing-footer__col--red">
            <h2 className="landing-footer__col-title h3">THNK home</h2>
            <address className="landing-footer__address">
              <a
                href="https://www.google.com/maps/place/Plantage+Middenlaan+45,+1018+DC+Amsterdam/@52.3655115,4.9127097,17z/data=!3m1!4b1!4m5!3m4!1s0x47c6099f50d4ec4b:0xe27408d4984a2edb!8m2!3d52.3655115!4d4.9148984?shorturl=1"
                target="_blank"
                rel="noreferrer"
              >
                Plantage Middenlaan 45
                <br />
                1018 DC Amsterdam
                <br />
                The Netherlands
              </a>
              <br />
              <br />
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  mailTo('amsterdam@thnk.org');
                }}
              >
                amsterdam@thnk.org
              </a>
            </address>
          </div>
          <div className="landing-footer__col landing-footer__col--grey">
            <h2 className="landing-footer__col-title h3">Follow</h2>
            <ul>
              <li>
                <a href="https://www.linkedin.com/company/969087" target="_blank" rel="noreferrer">
                  LinkedIn
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/thnkschool/" target="_blank" rel="noreferrer">
                  Instagram
                </a>
              </li>
              <li>
                <a href="https://twitter.com/THNKschool" target="_blank" rel="noreferrer">
                  Twitter
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/user/THNKAmsterdam?sub_confirmation=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  Youtube
                </a>
              </li>
              <li>
                <a href="https://medium.com/@THNK" target="_blank" rel="noreferrer">
                  Medium
                </a>
              </li>
            </ul>
          </div>
          <div className="landing-footer__subcontainer">
            <div className="landing-footer__col landing-footer__col--white">
              {isMobile ? null : <h2 className="landing-footer__col-title h3">Impact</h2>}
              <a
                href="https://www.bcorporation.net/en-us/find-a-b-corp/company/thnk-holding-b-v/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only">B Corp</span>
                {isMobile ? (
                  <CorpLogoSmall className="landing-tooter__corp absolute-center" />
                ) : (
                  <CorpLogo className="landing-tooter__corp" />
                )}
              </a>
            </div>
            <div className="landing-footer__col landing-footer__col--dark-blue">
              <Logo className="landing-footer__logo absolute-center" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default LandingFooter;
