import { useCallback } from 'react';

import CustomButton from '../../../common/CustomButton/CustomButton';
import CustomForm from '../../../common/CustomForm/CustomForm';
import CustomInput from '../../../common/CustomInput/CustomInput';
import Message from '../../../common/Message/Message';
import { PASSWORD_REGEX } from '../../../core/constants';
import { useUserContext } from '../../../core/context/user.context';
import { isEmpty } from '../../../core/helpers';
import useFetch from '../../../core/hooks/useFetch';
import { useFormInput } from '../../../core/hooks/useFormInput';
import { CurrentUser } from '../../../core/models';
import './SecurityForm.css';

function SecurityForm() {
  const { user, setUser, updateToken } = useUserContext();
  const currentPassword = useFormInput('', (value) => !isEmpty(value));
  const newPassword = useFormInput('', (value) => !isEmpty(value) && PASSWORD_REGEX.test(value));
  const repeatNewPassword = useFormInput(
    '',
    (value) => !isEmpty(value) && value === newPassword.value,
  );

  const { doFetch, error, loading } = useFetch(
    `${process.env.REACT_APP_API_URL}/user/change-password`,
    true,
  );

  const handleSubmit = useCallback(async () => {
    const res = await doFetch({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        old: currentPassword.value,
        new: newPassword.value,
      }),
    });
    if (res.data) {
      currentPassword.setValue('');
      newPassword.setValue('');
      repeatNewPassword.setValue('');
      setUser((previousState: CurrentUser) => ({ ...previousState, token: res.data }));
      updateToken(res.data);
    }
  }, [doFetch, user.token, currentPassword, newPassword, repeatNewPassword, setUser, updateToken]);

  return (
    <div className="security-form">
      <CustomForm
        inputs={[currentPassword, newPassword, repeatNewPassword]}
        onSubmit={handleSubmit}
      >
        <div className="form__section">
          <h2 className="form__title h3">Change password</h2>
          <CustomInput
            id="current-password"
            type="password"
            name="current-password"
            placeholder="Enter your password"
            label="Current password"
            input={currentPassword}
            link={{ text: 'Forgot your password?', href: '/forgot-password' }}
            emptyMessage="Please fill in your password"
            invalidMessage="Please fill in a valid password, minimum eight characters, one uppercase letter, one lowercase letter, one digit and one special character."
            autoComplete="off"
          />
          <CustomInput
            id="new-password"
            type="password"
            name="new-password"
            placeholder="Enter a new password"
            label="New password"
            input={newPassword}
            emptyMessage="Please fill in a new password"
            invalidMessage="Please fill in a valid password, minimum eight characters, one uppercase letter, one lowercase letter, one digit and one special character."
            autoComplete="off"
          />
          <CustomInput
            id="repeat-new-password"
            type="password"
            name="repeat-new-password"
            placeholder="Repeat your new password"
            label="Repeat your new password"
            input={repeatNewPassword}
            emptyMessage="Please repeat your new password"
            invalidMessage="Your passwords don't match"
            autoComplete="off"
          />
          <CustomButton
            className="security-form__cta btn btn--primary"
            type="submit"
            loading={loading}
          >
            Save changes
          </CustomButton>
        </div>
      </CustomForm>
      {!error ? null : <Message text={error.message} status="error" />}
    </div>
  );
}

export default SecurityForm;
