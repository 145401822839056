import HubSpotForm from '../HubSpotForm/HubSpotForm';
import './NewsLetterInline.css';

interface IProps {
  portalId: string;
  formId: string;
  region: string;
  id: string;
}

function NewsLetterInline({ portalId, formId, region, id }: IProps) {
  return (
    <div className="newsletter-inline">
      {!portalId || !formId || !region || !id ? null : (
        <HubSpotForm portalId={portalId} formId={formId} region={region} id={id} />
      )}
    </div>
  );
}

export default NewsLetterInline;
