import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import placeholder from '../../assets/placeholder.png';
import CustomLink from '../../common/CustomLink/CustomLink';
import Image from '../../common/Image/Image';
import LineClamp from '../../common/LineClamp/LineClamp';
import { useScreenSizeContext } from '../../core/context/screenSize.context';
import { shuffle } from '../../core/helpers';
import { ILink, Member } from '../../core/models';
import './PeopleShowcase.css';

interface IItem {
  user_id: Member;
  label: string;
}

interface IProps {
  title: string;
  cta: ILink;
  peopleShowcaseItems: IItem[];
}

function PeopleShowcase({ title, cta, peopleShowcaseItems }: IProps) {
  const { isTablet, isMobile } = useScreenSizeContext();
  const shuffledItems = useMemo(() => shuffle(peopleShowcaseItems), [peopleShowcaseItems]);

  const mainItem = useMemo(() => {
    if (!shuffledItems?.length) return null;
    const item: IItem = shuffledItems[0];

    if (!item) return null;
    return (
      <Link
        className="people-showcase__main flex gap-md py-sm px-sm"
        to={`/community/people/${item.user_id.slug}` || '/community'}
        target="_blank"
        key={item.user_id._id}
      >
        <div className="people-showcase__img-container">
          {item.user_id.profile_pic ? (
            <Image className="cover-img" img={item.user_id.profile_pic} size="medium" />
          ) : (
            <img className="cover-img" src={placeholder} alt="THNK member" />
          )}
        </div>
        <div>
          <h3 className="people-showcase__item-title h2">
            {item.user_id.firstname} {item.user_id.lastname}
          </h3>
          {!item.user_id.title ? null : (
            <div className="people-showcase__expertise truncate l-2 body-large">
              {item.user_id.title}
            </div>
          )}
        </div>
        {!item.user_id.bio ? null : (
          <LineClamp className="people-showcase__main-desc body-large" lines={6}>
            {item.user_id.bio}
          </LineClamp>
        )}
      </Link>
    );
  }, [shuffledItems]);

  const firstGroup = useMemo(() => {
    if (!shuffledItems?.length) return null;
    const items: IItem[] = shuffledItems.slice(1, 7);
    if (!items.length) return null;

    return items.map((item) => (
      <Link
        className="people-showcase__item flex gap-sm"
        to={`/community/people/${item.user_id.slug}` || '/community'}
        target="_blank"
        key={item.user_id._id}
      >
        <div className="people-showcase__img-container">
          {item.user_id.profile_pic ? (
            <Image className="cover-img" img={item.user_id.profile_pic} size="small" />
          ) : (
            <img className="cover-img" src={placeholder} alt="THNK member" />
          )}
        </div>
        <div>
          <h3 className="people-showcase__item-title h3">
            {item.user_id.firstname} {item.user_id.lastname}
          </h3>
          {!item.user_id.title ? null : (
            <div className="people-showcase__expertise truncate l-2 body-large">
              {item.user_id.title}
            </div>
          )}
        </div>
      </Link>
    ));
  }, [shuffledItems]);

  const lastGroup = useMemo(() => {
    if (!shuffledItems?.length) return null;
    const items: IItem[] = shuffledItems.slice(7, isTablet || isMobile ? 11 : 12);
    if (!items.length) return null;

    return items.map((item) => (
      <Link
        className="people-showcase__item flex gap-sm"
        to={`/community/people/${item.user_id.slug}` || '/community'}
        target="_blank"
        key={item.user_id._id}
      >
        <div className="people-showcase__img-container">
          {item.user_id.profile_pic ? (
            <Image className="cover-img" img={item.user_id.profile_pic} size="small" />
          ) : (
            <img className="cover-img" src={placeholder} alt="THNK member" />
          )}
        </div>
        <div>
          <h3 className="people-showcase__item-title h3">
            {item.user_id.firstname} {item.user_id.lastname}
          </h3>
          {!item.user_id.title ? null : (
            <div className="people-showcase__expertise truncate l-2 body-large">
              {item.user_id.title}
            </div>
          )}
        </div>
      </Link>
    ));
  }, [shuffledItems, isTablet, isMobile]);

  return (
    <section className="people-showcase section">
      <div className="wrap">
        <div className="people-showcase__content flex gap-lg">
          <h2 className="people-showcase__title h2">{title}</h2>
          {!peopleShowcaseItems.length ? null : (
            <div className="people-showcase__container gap-md">
              <div className="people-showcase__first-container gap-md">{firstGroup}</div>
              {mainItem}
              <div className="people-showcase__last-container gap-md">{lastGroup}</div>
            </div>
          )}
          {!cta ? null : <CustomLink className="people-showcase__cta" link={cta} />}
        </div>
      </div>
    </section>
  );
}

export default PeopleShowcase;
