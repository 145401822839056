import { useMemo } from 'react';

import { ILink } from '../../core/models';
import './Programs.css';
import ProgramsItem from './ProgramsItem/ProgramsItem';

interface IProps {
  title: string;
  desc?: string;
  programsItems: {
    title: string;
    desc?: string;
    link?: ILink;
    form: {
      portalId?: string;
      formId?: string;
      region?: string;
      id?: string;
      btnLabel?: string;
    };
  }[];
  slug: string;
}

function Programs({ title, desc, programsItems, slug }: IProps) {
  const cards = useMemo(
    // eslint-disable-next-line react/jsx-props-no-spreading
    () => programsItems?.map((item) => <ProgramsItem {...item} key={item.title} slug={slug} />),
    [programsItems, slug],
  );

  return (
    <section className="programs section-bg section-bg--grey">
      <div className="wrap flex gap-lg">
        <div className="programs__header flex flex--center gap-md">
          <h2 className="programs__title h2">{title}</h2>
          {!desc ? null : <p className="programs__desc body-large">{desc}</p>}
        </div>
        <div className="programs__container gap-lg">{cards}</div>
      </div>
    </section>
  );
}

export default Programs;
