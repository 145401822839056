import { Tag } from '../../core/models';
import './CustomTagField.css';

interface IProps {
  label: string;
  tags: Tag[];
}

function CustomTagField({ label, tags }: IProps) {
  return !tags.length ? null : (
    <div className="tag-field">
      <div className="tag-field__label body-label">{label}</div>
      <div className="tag-field__container">
        {tags.map((tag) => (
          <div className="tag-field__item tag" key={tag._id}>
            {tag.name}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CustomTagField;
