import { useCallback, useRef } from 'react';

import image from '../../assets/community/MarkVernooij.png';
import { Icon } from '../../common/Icon/Icon';
import IntersectionAnimation from '../../common/IntersectionAnimation/IntersectionAnimation';
import { useScreenSizeContext } from '../../core/context/screenSize.context';
import useIntersection from '../../core/hooks/useIntersection';
import useModal from '../../core/hooks/useModal';
import HubSpotForm from '../HubSpotForm/HubSpotForm';
import './LandingContact.css';

function LandingContact() {
  const { isMobile } = useScreenSizeContext();
  const { modal, open, close } = useModal();
  const btnRef = useRef(null);
  const titleRef = useRef(null);
  const descRef = useRef(null);
  const { wasIntersecting: titleWasIntersecting } = useIntersection(titleRef, 0.5);
  const { wasIntersecting: descWasIntersecting } = useIntersection(descRef, 0.5);

  const openModal = useCallback(() => {
    if (!btnRef.current) return;
    open(btnRef.current);
  }, [open]);

  return (
    <section className="landing-contact">
      <div className="wrap">
        <div className="landing-contact__container">
          <div className="landing-contact__header">
            <div className="landing-contact__header-top">
              <IntersectionAnimation side="left" visible={titleWasIntersecting}>
                <h2 className="landing-contact__title h2" ref={titleRef}>
                  Get in touch
                </h2>
              </IntersectionAnimation>
              {!isMobile ? null : (
                <div className="landing-contact__info">
                  <h3 className="landing-contact__name h4">Mark Vernooij</h3>
                  <div className="landing-contact__role body-small">Partner at THNK</div>
                </div>
              )}
            </div>
            <div className="landing-contact__header-bottom">
              {isMobile ? null : (
                <div className="landing-contact__info">
                  <h3 className="landing-contact__name h3">Mark Vernooij</h3>
                  <div className="landing-contact__role">Partner at THNK</div>
                </div>
              )}
              <img className="landing-contact__img" src={image} alt="Mark Vernooij" />
            </div>
          </div>
          <div className="landing-contact__content">
            <IntersectionAnimation side="bottom" visible={descWasIntersecting}>
              <p className="landing-contact__desc h3" ref={descRef}>
                Let&apos;s talk about your ambitions and have a good discussion on what is needed.
                We look forward to helping you build a powerful learning experience.
              </p>
              <button
                className="landing-contact__cta btn btn--big btn--light"
                type="button"
                ref={btnRef}
                onClick={openModal}
              >
                Get in touch
              </button>
            </IntersectionAnimation>
          </div>
        </div>
      </div>
      {modal({
        children: (
          <>
            <button className="modal__close" onClick={close} type="button">
              <Icon.Plus className="icon-large" />
              <span className="sr-only">Close modal</span>
            </button>
            <HubSpotForm
              portalId="4873583"
              formId="8a7732b4-c3c9-4de8-bcc0-86b9e0f368e5"
              region="na1"
              id="landing-contact-form"
            />
          </>
        ),
        keepMounted: true,
      })}
    </section>
  );
}

export default LandingContact;
