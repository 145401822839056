import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import AppRoutes from './AppRoutes';
import AppContextProvider from './core/context/appContextProvider';

if (process.env.REACT_APP_GIT_COMMIT_HASH) {
  // eslint-disable-next-line no-console
  console.log('version', process.env.REACT_APP_GIT_COMMIT_HASH);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <AppContextProvider>
          <AppRoutes />
        </AppContextProvider>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
