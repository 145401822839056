// eslint-disable-next-line @typescript-eslint/no-explicit-any
function LinkRenderer({ href, children }: any) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}

export default LinkRenderer;
