import { useMemo } from 'react';

import { Icon } from '../Icon/Icon';
import './Message.css';

interface Props {
  text: string;
  status: 'success' | 'warning' | 'error';
}

function Message({ text, status }: Props) {
  const IconElement = useMemo(() => {
    if (status === 'warning') return Icon.Exclamation;
    if (status === 'error') return Icon.Plus;
    return Icon.Check;
  }, [status]);

  return (
    <div
      className={`message${
        status === 'warning'
          ? ' message--warning'
          : status === 'error'
          ? ' message--error'
          : ' message--success'
      }`}
    >
      <IconElement />
      {text}
    </div>
  );
}

export default Message;
