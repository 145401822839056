import React from 'react';

import Message from '../Message/Message';
import './Alert.css';

interface IAlertProps {
  status?: 'success' | 'warning' | 'error';
  children: string;
}

function Alert({ status = 'success', children }: IAlertProps) {
  return (
    <div className="alert">
      <Message status={status} text={children} />
    </div>
  );
}

export default Alert;
