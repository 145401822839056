import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import countries from '../../assets/country-list.json';
import CustomButton from '../../common/CustomButton/CustomButton';
import CustomCheckbox from '../../common/CustomCheckbox/CustomCheckbox';
import CustomForm from '../../common/CustomForm/CustomForm';
import CustomInput from '../../common/CustomInput/CustomInput';
import CustomSelect from '../../common/CustomSelect/CustomSelect';
import Message from '../../common/Message/Message';
import { PASSWORD_REGEX } from '../../core/constants';
import { isEmpty } from '../../core/helpers';
import useFetch from '../../core/hooks/useFetch';
import { useFormCheckbox } from '../../core/hooks/useFormCheckbox';
import { useFormInput } from '../../core/hooks/useFormInput';
import './InviteForm.css';

const COUNTRY_LIST = countries.map((el: { code: string; name: string }) => ({
  value: el.code,
  label: el.name,
}));

function InviteForm() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const hash = searchParams.get('h');

  const {
    doFetch: checkInvitationLink,
    result: data,
    error: invitationError,
  } = useFetch(`${process.env.REACT_APP_API_URL}/user/invited-email/${hash}`);
  const {
    doFetch: registerUser,
    error,
    result,
    loading,
  } = useFetch(`${process.env.REACT_APP_API_URL}/user/register`);

  const password = useFormInput('', (value) => !isEmpty(value) && PASSWORD_REGEX.test(value));
  const repeatPassword = useFormInput('', (value) => !isEmpty(value) && value === password.value);
  const email = useFormInput(data?.data.email || '', (value) => !isEmpty(value));
  const termsCheckbox = useFormCheckbox(false, (value) => value);
  const firstName = useFormInput(data?.data.firstname || '', (value) => !isEmpty(value));
  const lastName = useFormInput(data?.data.lastname || '', (value) => !isEmpty(value));
  const country = useFormInput(data?.data.country || '', (value) => !isEmpty(value));

  const onSubmit = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const body: any = {
      email: email.value,
      password: password.value,
      firstname: firstName.value,
      lastname: lastName.value,
      country_code: country.value,
      accepted_conditions: termsCheckbox.checked,
    };

    if (data?.data._id) {
      body.connected_id = data.data._id;
    }

    await registerUser({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  };

  useEffect(() => {
    checkInvitationLink({
      method: 'GET',
    });
  }, [checkInvitationLink]);

  return result?.data ? (
    <div className="invite-form__container">
      <Message status="success" text="Thank you! You are now one step closer to entering THNK." />
      <Link className="invite-form__login btn btn--dark" to="/login">
        Log in
      </Link>
    </div>
  ) : (
    <div className="invite-form__container">
      <CustomForm
        onSubmit={onSubmit}
        inputs={[country, firstName, lastName, password, repeatPassword, termsCheckbox]}
        className="invite-form flex gap-sm"
      >
        {invitationError && <Message status="error" text={invitationError.message || ''} />}
        <CustomInput
          type="email"
          id="email"
          name="email"
          input={email}
          placeholder="Your email"
          label="Your email"
          disabled
        />
        <CustomInput
          id="firstName"
          placeholder="First name"
          name="firstName"
          type="text"
          input={firstName}
          label="What is your first name?"
          emptyMessage="First name is required"
        />
        <CustomInput
          id="lastName"
          placeholder="Last name"
          name="lastName"
          type="text"
          input={lastName}
          label="What is your last name?"
          emptyMessage="Last name is required"
        />
        <CustomSelect
          id="country"
          name="country"
          placeholder="Country"
          label="Country"
          options={COUNTRY_LIST}
          input={country}
          emptyMessage="Country is required"
        />
        <CustomInput
          type="password"
          id="password"
          name="password"
          input={password}
          placeholder="Your password"
          label="Your password"
          emptyMessage="Password is required"
          invalidMessage="Please fill in a valid password, minimum eight characters, one uppercase letter, one lowercase letter, one digit and one special character."
          autoComplete="off"
        />
        <CustomInput
          type="password"
          id="password-repeat"
          name="password-repeat"
          input={repeatPassword}
          placeholder="Repeat your password"
          label="Repeat your password"
          emptyMessage="It's required to repeat your password"
          invalidMessage="Your passwords don't match"
          autoComplete="off"
        />
        <CustomCheckbox
          id="terms-checkbox"
          name="terms-checkbox"
          input={termsCheckbox}
          label={
            <>
              I agree with the{' '}
              <Link to="/privacy-policy" target="_blank">
                Privacy Statement
              </Link>{' '}
              and{' '}
              <Link to="/terms-and-conditions" target="_blank">
                Terms & Conditions.
              </Link>
            </>
          }
          invalidMessage="You have to accept the privacy policy and terms of conditions"
        />
        <CustomButton
          type="submit"
          className="invite-form__button btn btn--dark"
          disabled={invitationError}
          loading={loading}
        >
          Create your account
        </CustomButton>
      </CustomForm>
      {error && <Message status="error" text={error.message || ''} />}
    </div>
  );
}

export default InviteForm;
