/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useState } from 'react';

import useFetch from '../../core/hooks/useFetch';
import { useFormInput } from '../../core/hooks/useFormInput';
import CustomInput from '../CustomInput/CustomInput';
import { Icon } from '../Icon/Icon';
import OutsideAlerter from '../OutsideAlerter/OutsideAlerter';
import './CustomPickField.css';

interface IProps {
  name: string;
  label: string;
  placeholder: string;
  path: string;
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  max?: number;
}

function CustomPickField({ name, label, placeholder, path, selected, setSelected, max }: IProps) {
  const search = useFormInput('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { doFetch, result } = useFetch(`${process.env.REACT_APP_API_URL}${path}?per_page=100`);
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  useEffect(() => {
    setFilteredItems(result?.data.items || []);
  }, [result?.data]);

  useEffect(() => {
    if (!result?.data.items) return;
    const items = result.data.items.filter((el: any) =>
      el.name.toLowerCase().includes(search.value.trim()),
    );
    setFilteredItems(items);
  }, [search.value, result?.data.items]);

  const removeItem = useCallback(
    (selectedId: string) => {
      const items = selected.filter((el) => el !== selectedId);
      setSelected(items);
    },
    [selected, setSelected],
  );

  const addItem = useCallback(
    (selectedId: string) => {
      setSelected((prevState) => [...prevState, selectedId]);
    },
    [setSelected],
  );

  const selectedItems = useMemo(() => {
    if (!result?.data || !selected) return null;
    const items = result.data.items?.filter((el: any) => selected.includes(el._id));
    return items.map((item: any) => (
      <button
        className="pick-field__selected body-small"
        type="button"
        key={item._id}
        onClick={() => removeItem(item._id)}
      >
        {item.name}
        <Icon.Plus className="icon rotate-45" />
      </button>
    ));
  }, [removeItem, result?.data, selected]);

  const options = useMemo(() => {
    if (!filteredItems) return null;
    const items = filteredItems.filter((el: any) => !selected.includes(el._id));
    return items.map((item: any) => (
      <button
        className="pick-field__option body-small"
        type="button"
        key={item._id}
        onClick={() => addItem(item._id)}
        disabled={max ? selected.length >= max : false}
      >
        {item.name}
      </button>
    ));
  }, [addItem, max, filteredItems, selected]);

  return (
    <div className="pick-field">
      <label className="pick-field__label body-label" htmlFor={name}>
        {label}
      </label>
      <OutsideAlerter onClick={() => setIsDropdownOpen(false)} detectTab>
        <div className="pick-field__container">
          {selectedItems}
          <CustomInput
            id={name}
            placeholder={placeholder}
            name={name}
            type="text"
            input={search}
            icon={<Icon.Search className="icon" />}
            onFocus={() => setIsDropdownOpen(true)}
          />
          <div
            className={`pick-field__dropdown${
              isDropdownOpen ? ' pick-field__dropdown--visible' : ''
            }`}
          >
            {options}
          </div>
        </div>
      </OutsideAlerter>
    </div>
  );
}

export default CustomPickField;
