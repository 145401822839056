import React, { useCallback, useMemo } from 'react';
import { createPortal } from 'react-dom';

import Alert from '../../common/Alert/Alert';

interface Alerts {
  message: string;
  status?: 'success' | 'warning' | 'error';
  id?: number;
}

interface AlertInterface {
  setAlert: (alert: Alerts) => void;
}

const AlertContext = React.createContext<AlertInterface | null>(null);

export const useAlert = () => React.useContext(AlertContext) as AlertInterface;

interface Props {
  children: React.ReactNode;
}

export function AlertContextProvider({ children }: Props) {
  const modalContainer: HTMLElement | null = document.getElementById('modal');
  const [alerts, setAlerts] = React.useState<Alerts[]>([]);

  const setAlert = useCallback(({ message, status = 'success' }: Alerts) => {
    const alert = {
      message,
      status,
      id: Date.now(),
    };
    setAlerts((prevState) => [...prevState, alert]);
    setTimeout(() => setAlerts((prevState) => prevState.filter((el) => el !== alert)), 5 * 1000);
  }, []);

  const value = useMemo(
    () => ({
      setAlert,
    }),
    [setAlert],
  );

  return (
    <AlertContext.Provider value={value}>
      {alerts.length && modalContainer
        ? createPortal(
            <div className="alert-container">
              {alerts.map((alert) => (
                // eslint-disable-next-line react/no-array-index-key
                <Alert status={alert.status} key={alert.id}>
                  {alert.message}
                </Alert>
              ))}
            </div>,
            modalContainer,
          )
        : null}
      {children}
    </AlertContext.Provider>
  );
}
