import { ReactComponent as World } from '../../assets/THNK-World-Logo.svg';
import { ReactComponent as Arrow } from '../../assets/sneak-arrow.svg';
import { ReactComponent as Circle } from '../../assets/sneak-circle.svg';
import { ReactComponent as Full } from '../../assets/symbol-full.svg';
import { ReactComponent as Simple } from '../../assets/symbol-monochrome.svg';
import CustomLink from '../../common/CustomLink/CustomLink';
import { Icon } from '../../common/Icon/Icon';
import Image from '../../common/Image/Image';
import VideoPlayer from '../../common/VideoPlayer/VideoPlayer';
import { IImage, ILink } from '../../core/models';
import './Promo.css';

interface IProps {
  title: string;
  desc?: string;
  info?: string;
  label?: string;
  video?: string;
  cta?: ILink;
  symbol?: 'full' | 'simple' | 'world';
  img?: IImage;
}

function Promo({ title, desc, info, label, video, cta, symbol, img }: IProps) {
  return (
    <section className="promo section">
      <div className="wrap">
        <div className="promo__container py-md px-sm">
          {!symbol && !label ? null : (
            <div className="promo__header">
              {!symbol ? null : symbol === 'world' ? (
                <World className="promo__symbol promo__symbol--world" />
              ) : symbol === 'full' ? (
                <Full className="promo__symbol" />
              ) : (
                <Simple className="promo__symbol" />
              )}
              {!label ? null : (
                <span className="promo__label">
                  {label}
                  <Circle className="promo__circle" />
                  <Arrow className="promo__arrow" />
                </span>
              )}
            </div>
          )}
          <div className="promo__content">
            <div className="promo__body">
              <h2 className="promo__title h2">{title}</h2>
              <p className="promo__desc body-large">{desc}</p>
              {!info ? null : (
                <div className="promo__info body-label">
                  <Icon.Info className="icon" />
                  {info}
                </div>
              )}
            </div>
            {!video ? null : (
              <div className="promo__video">
                <VideoPlayer src={video} />
              </div>
            )}
          </div>
          {!cta ? null : (
            <div className="promo__footer">
              {!cta ? null : <CustomLink className="promo__cta" link={cta} />}
            </div>
          )}
          {!img ? null : <Image className="promo__background cover-img" img={img} size="large" />}
        </div>
      </div>
    </section>
  );
}

export default Promo;
