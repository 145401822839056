import { useMemo } from 'react';

import Image from '../../common/Image/Image';
import { chunkIntoN } from '../../core/helpers';
import { IImage } from '../../core/models';
import './PartnersSlider.css';

interface IProps {
  title: string;
  partners: {
    img: IImage;
  }[];
}

function PartnersSlider({ title, partners }: IProps) {
  const partnerLanes = useMemo(
    () =>
      chunkIntoN([...partners], 3).map((lane, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="partners-slider__lane" key={i}>
          <div className="partners-slider__item-container">
            {lane.map((el) => (
              <div className="partners-slider__item" key={el.img._id}>
                <Image className="partners-slider__img contain-img" img={el.img} size="small" />
              </div>
            ))}
          </div>
          <div className="partners-slider__item-container">
            {lane.map((el) => (
              <div className="partners-slider__item" key={el.img._id}>
                <Image className="partners-slider__img contain-img" img={el.img} size="small" />
              </div>
            ))}
          </div>
        </div>
      )),
    [partners],
  );

  return (
    <section className="partners-slider section flex gap-lg">
      <div className="wrap">
        <h2 className="partners-slider__title h2">{title}</h2>
      </div>
      <div className="partners-slider__container">{partnerLanes}</div>
    </section>
  );
}

export default PartnersSlider;
