import { Outlet } from 'react-router-dom';

import LandingFooter from './Components/LandingFooter/LandingFooter';
import ScrollToTop from './Components/ScrollToTop';
import './style/animation.css';
import './style/base.css';
import './style/buttons.css';
import './style/inputs.css';
import './style/landing.css';
import './style/loader.css';
import './style/reset.css';
import './style/typography.css';
import './style/variables.css';

function LandingApp() {
  return (
    <div className="landing">
      <ScrollToTop />
      <Outlet />
      <LandingFooter />
    </div>
  );
}

export default LandingApp;
