import { createContext, useContext, useEffect, useMemo, useState } from 'react';

interface Props {
  children: JSX.Element;
}

interface Theme {
  isScrolled: boolean;
  hideLogo: boolean;
  pageColor: 'white' | 'grey';
  setPageColor: React.Dispatch<React.SetStateAction<'white' | 'grey'>>;
  setIsScrolled: React.Dispatch<React.SetStateAction<boolean>>;
  setHideLogo: React.Dispatch<React.SetStateAction<boolean>>;
}

const ThemeContext = createContext<Theme | null>(null);

export const useThemeContext = () => useContext(ThemeContext) as Theme;

export function ThemeContextProvider({ children }: Props) {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [pageColor, setPageColor] = useState<'white' | 'grey'>('white');
  const [hideLogo, setHideLogo] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (document.body.style.overflow === 'clip') return;
      setIsScrolled((document.documentElement.scrollTop || document.body.scrollTop) > 30);
    };
    document.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor =
      pageColor === 'white' ? 'white' : pageColor === 'grey' ? 'var(--surface100)' : '';
  }, [pageColor]);

  const value: Theme = useMemo(
    () => ({
      isScrolled,
      pageColor,
      setPageColor,
      setIsScrolled,
      hideLogo,
      setHideLogo,
    }),
    [isScrolled, pageColor, setPageColor, setIsScrolled, hideLogo, setHideLogo],
  );
  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
}
