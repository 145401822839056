import { Helmet } from 'react-helmet-async';

import ResetPasswordForm from '../../Components/ResetPasswordForm/ResetPasswordForm';
import { useDataContext } from '../../core/context/data.context';

function ResetPassword() {
  const {
    data: { generalData },
  } = useDataContext();
  return (
    <>
      <Helmet>
        {!generalData?.seo?.title ? null : <title>{generalData?.seo?.title}</title>}
        {!generalData?.seo?.description ? null : (
          <meta name="description" content={generalData?.seo?.description} />
        )}
      </Helmet>
      <section className="section">
        <div className="wrap wrap--xs">
          <h1 className="h2" style={{ marginBottom: '24px' }}>
            Create new password
          </h1>
          <ResetPasswordForm />
        </div>
      </section>
    </>
  );
}

export default ResetPassword;
