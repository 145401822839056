import { useEffect } from 'react';

import useFetch from '../../../core/hooks/useFetch';
import { IArticle, ILink } from '../../../core/models';
import Introduction from '../../Introduction/Introduction';
import ArticleFeatured from '../ArticleFeatured/ArticleFeatured';
import ArticleShowcase from '../ArticleShowcase/ArticleShowcase';
import ArticleSlider from '../ArticleSlider/ArticleSlider';
import './ArticleFront.css';

interface IProps {
  body: {
    featuredArticle1: {
      label: string;
      article: IArticle;
    };
    featuredArticle2: {
      label: string;
      article: IArticle;
    };
    featuredCategory1: {
      label: string;
      tag: string;
    };
    featuredCategory2: {
      label: string;
      tag: string;
    };
    featuredCategory3: {
      label: string;
      tag: string;
    };
    introduction: {
      title: string;
      desc?: string;
      cta?: ILink;
    };
  };
}

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

function ArticleFront({ body }: IProps) {
  const { result: latest, doFetch: doLatestFetch } = useFetch(
    `${BASE_URL}/articles/published?populate=tags&populate=authors&populate=img&per_page=4&sort=created:DESC`,
  );
  const { result: featuredCategory1, doFetch: doFeaturedCategoryFetch1 } = useFetch(
    `${BASE_URL}/articles/published?populate=tags&populate=authors&populate=img${
      !body.featuredCategory1?.tag ? '' : `&tags=${body.featuredCategory1.tag}`
    }&per_page=4&sort=created:DESC`,
  );
  const { result: featuredCategory2, doFetch: doFeaturedCategoryFetch2 } = useFetch(
    `${BASE_URL}/articles/published?populate=tags&populate=authors&populate=img${
      !body.featuredCategory2?.tag ? '' : `&tags=${body.featuredCategory2.tag}`
    }&per_page=4&sort=created:DESC`,
  );
  const { result: featuredCategory3, doFetch: doFeaturedCategoryFetch3 } = useFetch(
    `${BASE_URL}/articles/published?populate=tags&populate=authors&populate=img${
      !body.featuredCategory3?.tag ? '' : `&tags=${body.featuredCategory3.tag}`
    }&per_page=4&sort=created:DESC`,
  );

  useEffect(() => {
    doLatestFetch();
  }, [doLatestFetch]);

  useEffect(() => {
    if (!body.featuredCategory1?.tag) return;
    doFeaturedCategoryFetch1();
  }, [body.featuredCategory1?.tag, doFeaturedCategoryFetch1]);
  useEffect(() => {
    if (!body.featuredCategory2?.tag) return;
    doFeaturedCategoryFetch2();
  }, [body.featuredCategory2?.tag, doFeaturedCategoryFetch2]);
  useEffect(() => {
    if (!body.featuredCategory3?.tag) return;
    doFeaturedCategoryFetch3();
  }, [body.featuredCategory3?.tag, doFeaturedCategoryFetch3]);

  return (
    <div>
      <ArticleShowcase className="article-front section" articles={latest?.data.items} />
      <ArticleFeatured article={body.featuredArticle1?.article} styling="blue" />
      {!body.featuredCategory1?.tag ? null : (
        <ArticleShowcase
          className="section-bg section-bg--grey"
          title={body.featuredCategory1?.label}
          articles={featuredCategory1?.data.items}
        />
      )}
      {!body.featuredCategory2?.tag ? null : (
        <ArticleSlider
          title={body.featuredCategory2?.label}
          articles={featuredCategory2?.data.items}
        />
      )}
      <ArticleFeatured article={body.featuredArticle2?.article} styling="grey" />
      {!body.featuredCategory3?.tag ? null : (
        <ArticleShowcase
          className="section-bg section-bg--grey"
          title={body.featuredCategory3?.label}
          articles={featuredCategory3?.data.items}
          flip
        />
      )}
      <Introduction
        title={body.introduction.title}
        desc={body.introduction.desc}
        cta={body.introduction.cta}
      />
    </div>
  );
}

export default ArticleFront;
