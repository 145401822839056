import ReactMarkdown from 'react-markdown';

import Image from '../../../common/Image/Image';
import LinkRenderer from '../../../common/LinkRenderer/LinkRenderer';
import { IImage } from '../../../core/models';
import './ArticleBanner.css';

interface Props {
  text: string;
  img: IImage;
}

function ArticleBanner({ text, img }: Props) {
  return (
    <div className="article-banner">
      <div className="wrap wrap--lg">
        <div className="article-banner__container">
          {!text ? null : (
            <div className="article-banner__content">
              <ReactMarkdown
                className="article-banner__desc body-large"
                components={{
                  a: LinkRenderer,
                }}
              >
                {text}
              </ReactMarkdown>
            </div>
          )}
          {!img ? null : (
            <div className="article-banner__img-container">
              <div className="article-banner__img">
                <Image className="cover-img" img={img} size="medium" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ArticleBanner;
