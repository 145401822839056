import { useEffect, useRef, useState } from 'react';

import { isEmpty as isValueEmpty } from '../helpers';
import { CustomInputInteface } from '../models/inputs';

export const useFormInput = (
  initialValue: string,
  validate: (newValue: string) => boolean = () => true,
): CustomInputInteface => {
  const [value, setValue] = useState<string>(initialValue);
  const [isValid, setIsValid] = useState<boolean>(validate(value));
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [showError, setShowError] = useState<boolean>(false);
  const ref = useRef(null);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    setShowError(false);
    setIsEmpty(isValueEmpty(value));
    setIsValid(validate(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    setShowError(false);
    const newValue = e.currentTarget.value;
    setValue(newValue);
    setIsEmpty(isValueEmpty(newValue));
    setIsValid(validate(newValue));
  };

  return { value, onChange, isValid, isEmpty, showError, setShowError, setValue, ref };
};
