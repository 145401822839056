import React from 'react';
import { useNavigate } from 'react-router-dom';

import CustomButton from '../../../common/CustomButton/CustomButton';
import CustomDropdown from '../../../common/Dropdown/CustomDropdown';
import { useUserContext } from '../../../core/context/user.context';
import './UserDropdown.css';

function UserDropdown() {
  const { user, logout } = useUserContext();
  const navigate = useNavigate();

  return (
    <CustomDropdown
      selected=""
      items={[
        { value: 'account', label: 'Account' },
        { value: 'logout', label: 'Logout' },
      ]}
      handleChange={(e: React.MouseEvent<HTMLLIElement, MouseEvent>, val: string) => {
        switch (val) {
          case 'account':
            navigate('/user');
            break;
          case 'logout':
            logout();
            navigate('/');
            break;
          default:
            break;
        }
      }}
    >
      <CustomButton type="button" className="user-dropdown__avatar h4">
        {user?.firstname && user.firstname[0]}
        {user?.lastname && user.lastname[0]}
      </CustomButton>
    </CustomDropdown>
  );
}

export default UserDropdown;
