import { useCallback, useMemo, useRef, useState } from 'react';

import globe from '../../assets/globe-blue.png';
import asmlLogo from '../../assets/partners/ASML.svg';
import adyenLogo from '../../assets/partners/Adyen.svg';
import fugroLogo from '../../assets/partners/Fugro.svg';
import jAndJLogo from '../../assets/partners/Johnson&Johnson.svg';
import royalDSMLogo from '../../assets/partners/RoyalDSM.svg';
import siemensEnergyLogo from '../../assets/partners/SiemensEnergy.svg';
import unicefLogo from '../../assets/partners/unicef.svg';
import { Icon } from '../../common/Icon/Icon';
import IntersectionAnimation from '../../common/IntersectionAnimation/IntersectionAnimation';
import { useScreenSizeContext } from '../../core/context/screenSize.context';
import useIntersection from '../../core/hooks/useIntersection';
import useModal from '../../core/hooks/useModal';
import './LandingPartners.css';

interface Partner {
  id: string;
  name: string;
  logo: string;
  desc?: string;
  mobileOrder: number;
}

const PARTNERS: Partner[] = [
  {
    id: '0',
    name: 'Siemens Energy',
    logo: siemensEnergyLogo,
    // desc: 'Multi-year program to build the senior leadership pipeline (Top 400) during its spin-off from Siemens.',
    mobileOrder: 4,
  },
  {
    id: '1',
    name: 'Johnson & Johnson',
    logo: jAndJLogo,
    // desc: 'Multi-year journey to build innovation leadership mindsets and behaviors to solve large, complex global health challenges, including HIV and TB in sub-Saharan Africa, India, and China.',
    mobileOrder: 5,
  },
  {
    id: '3',
    name: 'ASML',
    logo: asmlLogo,
    mobileOrder: 0,
  },
  {
    id: '4',
    name: 'Adyen',
    logo: adyenLogo,
    mobileOrder: 1,
  },
  {
    id: '2',
    name: 'Unicef',
    logo: unicefLogo,
    // desc: 'Multiple top-team and culture change journeys, working in collaboration with the Public Partnership Division of UNICEF.',
    mobileOrder: 6,
  },
  {
    id: '5',
    name: 'Fugro',
    logo: fugroLogo,
    mobileOrder: 2,
  },
  {
    id: '6',
    name: 'Royal DSM',
    logo: royalDSMLogo,
    mobileOrder: 3,
  },
];

function LandingPartners() {
  const { isMobile } = useScreenSizeContext();
  const { modal, open, close } = useModal();
  const btnRef = useRef<{ btn: HTMLButtonElement | null; id: string }[]>([]);
  const [selectedPartnerId, setSelectedPartnerId] = useState<string | null>(null);
  const titleRef = useRef(null);
  const descRef = useRef(null);
  const { wasIntersecting: titleWasIntersecting } = useIntersection(titleRef, 0.5);
  const { wasIntersecting: descWasIntersecting } = useIntersection(descRef, 0.5);

  const handlePartnerClick = useCallback(
    (id: string) => {
      if (!btnRef.current) return;
      setSelectedPartnerId(id);
      const clickedBtn = btnRef.current.find(
        (el: { btn: HTMLButtonElement | null; id: string }) => el.id === id,
      )?.btn;
      if (!clickedBtn) return;
      open(clickedBtn);
    },
    [open],
  );

  const selectedPartner = useMemo(() => {
    if (!selectedPartnerId) return <div />;
    const partner = PARTNERS.find((el) => el.id === selectedPartnerId);
    if (!partner) return <div />;
    return (
      <div className="landing-partners__info">
        <button className="landing-partners__close" type="button" onClick={close}>
          <Icon.Plus className="icon-large " />
          <span className="sr-only">Close Modal</span>
        </button>
        <h3 className="landing-partners__name h3">{partner.name}</h3>
        <div className="landing-partners__info-desc">{partner.desc}</div>
      </div>
    );
  }, [close, selectedPartnerId]);

  const partnerCard = useCallback(
    (el: Partner, i: number) =>
      isMobile ? (
        !el.desc ? (
          <div className="landing-partners__partner" key={el.name}>
            <img className="landing-partners__logo" src={el.logo} alt={el.name} />
          </div>
        ) : (
          <button
            className="landing-partners__partner"
            type="button"
            key={el.name}
            onClick={() => handlePartnerClick(el.id)}
            ref={(btn) => {
              btnRef.current[i] = { id: el.id, btn };
            }}
          >
            <img className="landing-partners__logo" src={el.logo} alt={el.name} />
          </button>
        )
      ) : (
        <div
          className={`landing-partners__partner${
            !el.desc ? '' : ' landing-partners__partner--hover'
          }`}
          key={el.name}
        >
          <img className="landing-partners__logo" src={el.logo} alt={el.name} />
          {!el.desc ? null : <p className="landing-partners__partner-desc">{el.desc}</p>}
        </div>
      ),
    [handlePartnerClick, isMobile],
  );

  return (
    <section className="landing-partners">
      <div className="wrap">
        <div className="landing-partners__container">
          <div className="landing-partners__desc-container">
            <IntersectionAnimation side="left" visible={descWasIntersecting}>
              <p className="landing-partners__desc h3" ref={descRef}>
                Our clients need to adapt to today&apos;s demanding and rapidly changing market
                environments. We co-create personal development journeys to meet their specific
                needs and enable senior leadership to lead change.
              </p>
            </IntersectionAnimation>
          </div>
          <div className="landing-partners__title-container">
            <IntersectionAnimation side="right" visible={titleWasIntersecting}>
              <h2 className="landing-partners__title h2" ref={titleRef}>
                We partner with
                <br />
                organisations
                <br />
                around the
                <br />
                world
              </h2>
            </IntersectionAnimation>
          </div>
          <div className="landing-partners__partner-container">
            {isMobile
              ? [...PARTNERS]
                  .sort((a, b) => a.mobileOrder - b.mobileOrder)
                  .slice(0, 4)
                  .map((el, i) => partnerCard(el, i + 3))
              : [...PARTNERS].slice(0, 4).map((el, i) => partnerCard(el, i))}
          </div>
          <div className="landing-partners__partner-container">
            {isMobile
              ? [...PARTNERS]
                  .sort((a, b) => a.mobileOrder - b.mobileOrder)
                  .slice(4)
                  .map((el, i) => partnerCard(el, i))
              : [...PARTNERS].slice(4).map((el, i) => partnerCard(el, i + 4))}
            <div className="landing-partners__img-container">
              <img className="landing-partners__img" src={globe} alt="Half a globe in blue color" />
            </div>
          </div>
        </div>
      </div>
      {!isMobile
        ? null
        : modal({
            children: selectedPartner,
            side: 'bottom',
          })}
    </section>
  );
}

export default LandingPartners;
