import { Link } from 'react-router-dom';

import { ILink } from '../../core/models';
import { Icon } from '../Icon/Icon';

interface IProps {
  link: ILink;
  className?: string;
  target?: string;
}

function CustomLink({ link, className, target }: IProps) {
  return !link.text ? null : (
    <Link
      className={`${className ? `${className} ` : ''}btn btn--${link.color ? link.color : 'dark'}`}
      to={link.href}
      target={target}
      download={link.isDownload}
      id={link.id || ''}
    >
      {link.text}
      {!link.hasArrow ? null : <Icon.Chevron className="icon rotate-minus-90" />}
      {!link.isDownload ? null : <Icon.External className="icon" />}
    </Link>
  );
}

export default CustomLink;
