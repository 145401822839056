import { Wrapper } from '@googlemaps/react-wrapper';
import { Helmet } from 'react-helmet-async';

import UserEditForm from '../../../Components/User/UserEditForm/UserEditForm';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { useDataContext } from '../../../core/context/data.context';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY || '';

function Edit() {
  const {
    data: { generalData },
  } = useDataContext();

  return (
    <Wrapper libraries={['places']} apiKey={GOOGLE_MAPS_API_KEY}>
      <Helmet>
        {!generalData?.seo?.title ? null : <title>{generalData?.seo?.title}</title>}
        {!generalData?.seo?.description ? null : (
          <meta name="description" content={generalData?.seo?.description} />
        )}
      </Helmet>
      <section className="user-edit">
        <div className="wrap wrap--xs">
          <PageTitle title="Edit profile" backLabel="Back" backPath="/user" />
        </div>
        <div className="wrap wrap--xs">
          <UserEditForm />
        </div>
      </section>
    </Wrapper>
  );
}

export default Edit;
