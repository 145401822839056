import Image from '../../common/Image/Image';
import { IImage } from '../../core/models';
import './ImageSection.css';

interface IProps {
  img: IImage;
  full: boolean;
  size?: 'large' | 'medium' | 'small';
}

function ImageSection({ img, full, size }: IProps) {
  return (
    <section className="image-section section">
      <div className="wrap">
        <div className="image-section__container">
          <Image
            className={`image-section__img${full ? ' image-section__img--full' : ''}`}
            img={img}
            size={size}
          />
        </div>
      </div>
    </section>
  );
}

export default ImageSection;
