import img from '../../assets/landing-banner.png';
import { ReactComponent as LogoMonochrome } from '../../assets/logo-monochrome.svg';
import { ReactComponent as LogoColor } from '../../assets/logo.svg';
import './LandingBanner.css';

function LandingBanner() {
  return (
    <section className="landing-banner">
      <div className="wrap">
        <h1 className="landing-banner__title h1">
          <span className="landing-banner__title--left">
            <LogoMonochrome className="landing-banner__title-logo" /> of a
            <br />
            leader that&apos;s
            <br />a listener
          </span>
          <span className="landing-banner__title--right">
            instead
            <br /> of the
            <br /> loudest in
            <br /> the room.
          </span>
        </h1>
        <h2 className="landing-banner__subtitle h3">
          <LogoColor className="landing-banner__subtitle-logo" />
          <span>School of Leadership</span>
        </h2>
        <img className="landing-banner__img" src={img} alt="dreaming-head-monkey" />
      </div>
    </section>
  );
}

export default LandingBanner;
