interface Props {
  children: React.ReactNode;
  side: 'top' | 'bottom' | 'left' | 'right';
  visible: boolean;
}

function IntersectionAnimation({ children, side, visible }: Props) {
  return (
    <div className={`intersect intersect--${side}${visible ? ' intersect--visible' : ''}`}>
      {children}
    </div>
  );
}

export default IntersectionAnimation;
