/* eslint-disable react/jsx-pascal-case */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import debby from '../../assets/community/DebbyJannink.png';
import florence from '../../assets/community/FlorenceAinsworth.png';
import lieselotte from '../../assets/community/LieselotteNooyen.png';
import mandy from '../../assets/community/MandyChooi.png';
import mark from '../../assets/community/MarkVernooij.png';
import natasha from '../../assets/community/NatashaBonnevalle.png';
import paul from "../../assets/community/Paulvan'tVeld.png";
import rajiv from '../../assets/community/RajivBall.png';
import rod from '../../assets/community/RodBenZeev.png';
import { Icon } from '../../common/Icon/Icon';
import IntersectionAnimation from '../../common/IntersectionAnimation/IntersectionAnimation';
import { useScreenSizeContext } from '../../core/context/screenSize.context';
import useIntersection from '../../core/hooks/useIntersection';
import useModal from '../../core/hooks/useModal';
import './LandingPeople.css';

const PEOPLE = [
  {
    id: '0',
    img: {
      uri: natasha,
      alt: 'Natasha Bonnevalle',
    },
    name: 'Natasha Bonnevalle',
    role: 'Partner',
    desc: "Natasha's expertise is leadership development in the context of business transformation. She specializes in supporting boards and senior executive teams.",
    link: {
      text: "Let's connect",
      href: 'https://nl.linkedin.com/in/natashabonnevalle',
    },
    color: 'light-blue',
    mobileColor: 'light-blue',
  },
  {
    id: '1',
    img: {
      uri: lieselotte,
      alt: 'Lieselotte Nooyen',
    },
    name: 'Lieselotte Nooyen',
    role: 'Senior facilitator and program architect',
    desc: 'Lieselotte curates transformational experiences for senior leaders, drawing from diverse traditions to foster personal leadership and forge deeper connections.',
    link: {
      text: "Let's connect",
      href: 'https://nl.linkedin.com/pub/lieselotte-nooyen/2/536/210',
    },
    color: 'dark-blue',
    mobileColor: 'dark-blue',
  },
  {
    id: '2',
    img: {
      uri: paul,
      alt: "Paul van 't Veld",
    },
    name: "Paul van 't Veld",
    role: 'Facilitator and coach',
    desc: 'Paul is a firm believer in authentic leadership. He helps senior executives draw a connection with their head, heart and body so they can lead with authenticity.',
    link: {
      text: "Let's connect",
      href: 'https://www.linkedin.com/in/paul-van-t-veld/',
    },
    color: 'light-blue',
    mobileColor: 'red',
  },
  {
    id: '3',
    img: {
      uri: florence,
      alt: 'Florence Ainsworth',
    },
    name: 'Florence Ainsworth',
    role: 'Leadership coach and facilitator',
    desc: 'Florence is dedicated to guiding people towards authentic, courageous, and inclusive leadership for a fair and balanced environment for all to thrive!',
    link: {
      text: "Let's connect",
      href: 'https://www.linkedin.com/in/florence-ainsworth-6a24325',
    },
    color: 'dark-blue',
    mobileColor: 'grey',
  },
  {
    id: '4',
    img: {
      uri: rod,
      alt: 'Rod Ben Zeev',
    },
    name: 'Rod Ben Zeev',
    role: 'Facilitator and program designer',
    desc: 'Rod is a master storyteller who believes improvisation skills help leaders become more agile, collaborative, and innovative under pressure.',
    link: {
      text: "Let's connect",
      href: 'https://nl.linkedin.com/in/rod-ben-zeev-777b993',
    },
    color: 'grey',
    mobileColor: 'light-blue',
  },
  {
    id: '5',
    img: {
      uri: mandy,
      alt: 'Mandy Chooi',
    },
    name: 'Mandy Chooi',
    role: 'Program director, facilitator and coach',
    desc: "Mandy's expertise is in organizational change within complex and demanding contexts. She also specializes in helping leaders build purposeful organizations.",
    link: {
      text: "Let's connect",
      href: 'https://www.linkedin.com/in/mandychooi',
    },
    color: 'red',
    mobileColor: 'dark-blue',
  },
  {
    id: '6',
    img: {
      uri: debby,
      alt: 'Debby Jannink',
    },
    name: 'Debby Jannink',
    role: 'Partner',
    desc: 'Debby spreads conscious leadership. She encourages leaders to discover their voice and purpose so they can lead with heart.',
    link: {
      text: "Let's connect",
      href: 'https://www.linkedin.com/in/debby-jannink-839461/',
    },
    color: 'grey',
    mobileColor: 'grey',
  },
  {
    id: '7',
    img: {
      uri: mark,
      alt: 'Mark Vernooij',
    },
    name: 'Mark Vernooij',
    role: 'Partner',
    desc: 'Mark specializes in leadership in the context of transformation and innovation. He focuses on working with dynamic leaders to tackle global challenges at scale.',
    link: {
      text: "Let's connect",
      href: 'https://nl.linkedin.com/in/markvernooy',
    },
    color: 'red',
    mobileColor: 'red',
  },
  {
    id: '8',
    img: {
      uri: rajiv,
      alt: 'Rajiv Ball',
    },
    name: 'Rajiv Ball',
    role: 'Partner',
    desc: 'Rajiv combines business experience with an understanding of people to support top teams and executives to transform their organizations.',
    link: {
      text: "Let's connect",
      href: 'https://nl.linkedin.com/in/rajivball',
    },
    color: 'dark-blue',
    mobileColor: 'dark-blue',
  },
];

function LandingPeople() {
  const { isMobile, width } = useScreenSizeContext();
  const ref = useRef<HTMLDivElement>(null);
  const btnRef = useRef<{ btn: HTMLButtonElement | null; id: string }[]>([]);
  const { modal, open, close } = useModal();
  const [selectedPersonId, setSelectedPersonId] = useState<string | null>(null);
  const titleRef = useRef(null);
  const { wasIntersecting: titleWasIntersecting } = useIntersection(titleRef, 0.5);
  const statsRef = useRef(null);
  const { wasIntersecting: statsWasIntersecting } = useIntersection(statsRef, 0.5);

  useEffect(() => {
    if (!ref.current) return;

    ref.current.style.setProperty('--ew', `${ref.current.offsetWidth * 0.01}px`);
  }, [width]);

  const handlePersonClick = useCallback(
    (id: string) => {
      if (!btnRef.current) return;
      setSelectedPersonId(id);
      const clickedBtn = btnRef.current.find(
        (el: { btn: HTMLButtonElement | null; id: string }) => el.id === id,
      )?.btn;
      if (!clickedBtn) return;
      open(clickedBtn);
    },
    [open],
  );

  const selectedPerson = useMemo(() => {
    if (!selectedPersonId) return <div />;
    const person = PEOPLE.find((el) => el.id === selectedPersonId);
    if (!person) return <div />;
    return (
      <div className="landing-people__info">
        <button className="landing-people__close" type="button" onClick={close}>
          <Icon.Plus className="icon-large " />
          <span className="sr-only">Close Modal</span>
        </button>
        <h3 className="landing-people__name h3">{person.name}</h3>
        <div className="landing-people__role">{person.role}</div>
        <div className="landing-people__desc">{person.desc}</div>
        <a
          className="landing-people__link"
          href={person.link.href}
          target="_blank"
          rel="noreferrer"
        >
          <Icon.LinkedIn className="icon-large" />
          {person.link.text}
        </a>
      </div>
    );
  }, [close, selectedPersonId]);

  const mobileButtons = useMemo(
    () =>
      PEOPLE.map((el, i) => (
        <button
          className={`landing-people__single landing-people__person ${el.mobileColor}`}
          key={el.id}
          type="button"
          onClick={() => handlePersonClick(el.id)}
          ref={(btn) => {
            btnRef.current[i] = { id: el.id, btn };
          }}
        >
          <img className="landing-people__img" src={el.img.uri} alt={el.img.alt} />
        </button>
      )),
    [handlePersonClick],
  );

  return (
    <section className="landing-people">
      <div className="wrap" ref={ref}>
        <div className="landing-people__container">
          <div className="landing-people__double">
            <IntersectionAnimation side="left" visible={titleWasIntersecting}>
              <h2 className="landing-people__title h2" ref={titleRef}>
                Your guides on this journey
              </h2>
            </IntersectionAnimation>
          </div>
          {isMobile
            ? mobileButtons
            : PEOPLE.map((el) => (
                <div
                  className={`landing-people__single landing-people__person ${el.color}`}
                  key={el.name}
                >
                  <img className="landing-people__img" src={el.img.uri} alt={el.img.alt} />
                  <div className="landing-people__info">
                    <h3 className="landing-people__name h3">{el.name}</h3>
                    <div className="landing-people__role">{el.role}</div>
                    <div className="landing-people__desc">{el.desc}</div>
                    <a
                      className="landing-people__link"
                      href={el.link.href}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon.LinkedIn className="icon-large" />
                      {el.link.text}
                    </a>
                  </div>
                </div>
              ))}
          <div className="landing-people__single light-blue">
            <div className="landing-people__stats" ref={statsRef}>
              <IntersectionAnimation side="right" visible={statsWasIntersecting}>
                <h3 className="landing-people__stat h3">200+ specialists, mentors and coaches</h3>
                <div>who design and facilitate your program.</div>
              </IntersectionAnimation>
            </div>
          </div>
        </div>
      </div>
      {!isMobile
        ? null
        : modal({
            children: selectedPerson,
            side: 'bottom',
          })}
    </section>
  );
}

export default LandingPeople;
