/* eslint-disable jsx-a11y/media-has-caption */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';
import 'videojs-youtube';

import './VideoPlayer.css';

const YOUTUBE_REGEX =
  /(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtube\.com|youtu.be)\/(?:watch\?v=|embed\/|v\/)?([a-zA-Z0-9\-_]+)/;

function VideoPlayer({ src }: any) {
  const videoRef = useRef<any>(null);
  const playerRef = useRef<any>(null);

  useEffect(() => {
    if (!playerRef.current) {
      if (!videoRef.current) return;
      let player: any;
      const videoElement = document.createElement('video');
      videoElement.classList.add('video-js', 'cover-img');
      videoElement.controls = true;
      videoRef.current.appendChild(videoElement);
      if (YOUTUBE_REGEX.test(src)) {
        player = videojs(videoElement, {
          src: {
            type: 'video/mp4',
            src,
          },
          techOrder: ['youtube'],
          sources: [
            {
              type: 'video/youtube',
              src,
            },
          ],
          youtube: { ytControls: 0 },
          controlBar: {
            fluid: true,
            pictureInPictureToggle: false,
          },
        });
        playerRef.current = player;
      } else {
        player = videojs(
          videoElement,
          {
            sources: [
              {
                src,
                type: 'application/x-mpegURL',
              },
            ],
            controlBar: {
              fluid: true,
              pictureInPictureToggle: false,
            },
            html5: {
              vhs: {
                overrideNative: true,
              },
            },
          },
          () => {
            player.hlsQualitySelector();
          },
        );
        playerRef.current = player;
      }
      player.volume(0.5);
    } else {
      const player: any = playerRef.current;
      player.src(src);
      player.volume(0.5);
    }
  }, [videoRef, src]);

  useEffect(() => {
    const player: any = playerRef.current;
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <span className="video" data-vjs-player>
      <span className="video__container">
        <span ref={videoRef} />
      </span>
    </span>
  );
}

export default VideoPlayer;
